import * as React from 'react'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import { User } from 'store/modules/users'
import { Requirement } from 'store/modules/requirements'
import { Tooltip } from 'src/design-system'

export type PositionProgressBarsProps = {
  user: User
  requirements: Requirement[]
  className?: string
}

export const PositionProgressBars = observer(
  (props: PositionProgressBarsProps) => {
    const { user, requirements, className } = props

    const progress = {
      new: 0,
      working_towards: 0,
      meeting: 0,
      exceeding: 0,
    }

    requirements.forEach((req) => {
      const skill = user
        .sortedUserSkills()
        .find((userSkill) => userSkill.skill.id === req.skill.id)

      if (!req.skillLevel?.level) {
        return
      }

      const reqLevel: number = req.skillLevel.level
      const curLevel = skill?.positionRequirementLevel
      if (curLevel === null || curLevel === undefined) {
        progress.new++
        return
      }
      if (reqLevel < curLevel) {
        progress.exceeding++
        return
      }
      if (reqLevel === curLevel) {
        progress.meeting++
        return
      }
      progress.working_towards++
    })

    return (
      <div
        role="progressbar"
        className={cn('h-2 flex gap-0.5 children:rounded-sm', className)}
      >
        {progress.new > 0 && (
          <ProgressSegment
            className="bg-gray-200"
            value={progress.new}
            label="New"
          />
        )}
        {progress.working_towards > 0 && (
          <ProgressSegment
            className="bg-working-towards"
            value={progress.working_towards}
            label="Working Towards"
          />
        )}
        {progress.meeting > 0 && (
          <ProgressSegment
            className="bg-meeting"
            value={progress.meeting}
            label="Meeting"
          />
        )}
        {progress.exceeding > 0 && (
          <ProgressSegment
            className="bg-exceeding"
            value={progress.exceeding}
            label="Exceeding"
          />
        )}
      </div>
    )
  }
)

type ProgressSegmentProps = {
  value: number
  label: string
  className?: string
}

const ProgressSegment = ({ className, value, label }: ProgressSegmentProps) => {
  const tooltip = (
    <span className="flex items-center gap-1">
      <div className={cn('inline-block w-2 h-2 rounded-full', className)} />
      <strong>{value}</strong>
      {label}
    </span>
  )
  return (
    <div className={className} style={{ flex: value }}>
      <Tooltip content={tooltip} className="w-full h-full">
        {/*  */}
      </Tooltip>
    </div>
  )
}
