import * as React from 'react'
import { BaseAxisProps } from 'recharts/types/util/types'

export const GrowthOverTimeYAxisTick: BaseAxisProps['tick'] = (props) => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x - 6},${y + 3})`}>
      <text
        x={0}
        y={0}
        textAnchor="middle"
        className="fill-current text-gray-600 text-xs"
      >
        L{payload.value}
      </text>
    </g>
  )
}
