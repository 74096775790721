import * as React from 'react'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import { UserSkill } from 'store/modules/user_skills'
import { Button, Tag, Tooltip } from 'src/design-system'
import { CheckinSkillLevelLabel } from 'components/checkin-skill-level-label'
import { Streaks } from './streaks'
import { ActivityCounts } from './activity-counts'
import { Shuffle, SidebarSimple, Star } from '@phosphor-icons/react'
import { FocusSkillStar } from 'components/focus-skill-star'
import { SOURCE } from 'pages/growth-profile-page/utils'
import { useFocusSkillRow } from './use-focus-skill-row'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { PackageProtect } from 'components/package-protect'

// There seems to be global styling that overrides a simple `border-b`
export const rowBorderClasses =
  'border-solid border-t-0 border-r-0 border-l-0 border-b border-gray-100'
const spacingClasses = 'children:px-2 children:py-6'
const hoverClasses = 'cursor-pointer transition-colors hover:bg-gray-50/50'

export type FocusSkillRowProps = {
  className?: string
  userSkill: UserSkill
}

export const FocusSkillRow = observer((props: FocusSkillRowProps) => {
  const { userSkill, className } = props

  const {
    checkinSkillLevel,
    clickable,
    editable,
    onClickViewActivity,
    skillRequirementLevel,
    user,
  } = useFocusSkillRow(userSkill)

  return (
    <tr
      className={cn(rowBorderClasses, spacingClasses, className, {
        [hoverClasses]: clickable,
      })}
      onClick={onClickViewActivity}
    >
      <td className="max-w-[250px] w-[250px]">
        <div className="flex items-center gap-1">
          <strong className="text-left">{userSkill.skill.name}</strong>
          <FocusSkillStar
            editable={editable}
            source={SOURCE}
            userSkill={userSkill}
          />
        </div>
        <PackageProtect include={[]} exclude={['grow']}>
          <HtmlContent className="my-1 text-gray-600">
            {userSkill.skill.description}
          </HtmlContent>
        </PackageProtect>
      </td>
      <td>
        <div className="h-full flex items-center gap-2">
          <Tooltip
            content="The required level for your position"
            contentClassName="whitespace-nowrap max-w-none"
          >
            <Tag variant="paper">L{skillRequirementLevel}</Tag>
          </Tooltip>
          <PackageProtect>
            <CheckinSkillLevelLabel
              level={checkinSkillLevel}
              requiredLevel={skillRequirementLevel}
            />
          </PackageProtect>
        </div>
      </td>
      <PackageProtect>
        <td>
          <ActivityCounts user={user} skill={userSkill.skill} />
        </td>
        <td>
          <Streaks user={user} skill={userSkill.skill} />
        </td>
        <td className="text-end">
          <SidebarSimple weight="fill" />
        </td>
      </PackageProtect>
    </tr>
  )
})

export type SuggestedSkillRowProps = FocusSkillRowProps & {
  onFocusClick?: (userSkill: UserSkill) => void
  onShuffleClick?: (userSkill: UserSkill) => void
}

export const SuggestedSkillRow = observer((props: SuggestedSkillRowProps) => {
  const { userSkill, onFocusClick, onShuffleClick, className = '' } = props

  const {
    checkinSkillLevel,
    clickable,
    editable,
    onClickViewActivity,
    skillRequirementLevel,
  } = useFocusSkillRow(userSkill)

  const handleFocus: React.MouseEventHandler = (event) => {
    event.stopPropagation()
    onFocusClick?.(userSkill)
  }

  const handleShuffle: React.MouseEventHandler = (event) => {
    event.stopPropagation()
    onShuffleClick?.(userSkill)
  }

  return (
    <tr
      className={cn(spacingClasses, className, { [hoverClasses]: clickable })}
      onClick={onClickViewActivity}
    >
      <td className="max-w-[250px] w-[250px]">
        <div className="flex items-center gap-2 text-gray-600">
          <strong className="text-left truncate">{userSkill.skill.name}</strong>
          <span className="text-xs">Suggested</span>
          <FocusSkillStar
            editable={editable}
            source={SOURCE}
            userSkill={userSkill}
          />
        </div>
        <PackageProtect include={[]} exclude={['grow']}>
          <HtmlContent className="my-1 text-gray-600">
            {userSkill.skill.description}
          </HtmlContent>
        </PackageProtect>
      </td>
      <td>
        <div className="h-full flex items-center gap-2">
          <Tooltip
            content="The required level for your position"
            contentClassName="whitespace-nowrap max-w-none"
          >
            <Tag variant="paper">L{skillRequirementLevel}</Tag>
          </Tooltip>
          <PackageProtect>
            <CheckinSkillLevelLabel
              level={checkinSkillLevel}
              requiredLevel={skillRequirementLevel}
            />
          </PackageProtect>
        </div>
      </td>
      <PackageProtect>
        <td colSpan={3} align="right">
          {editable && (
            <div className="flex items-center justify-end gap-1">
              <Button variant="outline" onClick={handleShuffle}>
                <Shuffle /> Shuffle
              </Button>
              <Button onClick={handleFocus}>
                <Star /> Star
              </Button>
            </div>
          )}
        </td>
      </PackageProtect>
    </tr>
  )
})
