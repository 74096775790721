import { store } from 'store/index'

export class UserSettingsVm {
  constructor(private userId: string) {}

  get user() {
    const fetchUsers = () => {
      store.users.fetchOne(this.userId, {
        include: ['team', 'position'],
      })
      return store.users.byId(this.userId)
    }

    return fetchUsers()
  }

  get initialTeamId(): string | undefined {
    return this.user?.team?.id
  }

  get initialPositionId(): string | undefined {
    return this.user?.position?.id
  }

  get managerSelectUsers() {
    return store.users.active
  }

  positionSelectPositions(teamId: string | undefined) {
    if (!teamId) return store.positions.forOrg

    return store.positions.forOrg.filter(
      (position) => position.team.id === teamId
    )
  }
}
