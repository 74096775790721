import React from 'react'
import { TabbedContent } from 'src/design-system'

export type InboxTabProps = {
  id: string
  children: React.ReactNode
}

export const InboxTab: React.VFC<InboxTabProps> = (props) => {
  const { id, children } = props
  return (
    <TabbedContent.Tab
      id={id}
      className="py-1 px-2 rounded border-px border-solid border-transparent text-theme-30 after:content-none aria-[current=page]:bg-theme-80 aria-[current=page]:border-theme-80 hover:border-theme-80 hover:bg-theme-80/80"
    >
      {children}
    </TabbedContent.Tab>
  )
}
