import * as React from 'react'
import { WithAnalytics } from 'components/with-analytics'
import { GrowthOverTimeChartEmptyStateVm } from './growth-over-time-chart-empty-state-vm'
import { Loader } from 'src/design-system'
import { store } from 'store/index'

export type GrowthOverTimeChartEmptyState = {
  userId: string
}

export const GrowthOverTimeChartEmptyState: React.VFC<
  GrowthOverTimeChartEmptyState
> = (props) => {
  const { userId } = props
  const loading = store.checkins.initialLoading()

  const vm = new GrowthOverTimeChartEmptyStateVm(userId)

  return (
    <div className="w-3/5 h-72 mt-6 absolute flex z-10 right-8">
      <div className="w-36 h-full bg-gradient-to-r from-white/0 to-white"></div>
      <div className="bg-white h-full flex flex-col justify-center content-center align-middle flex-grow">
        {loading ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <>
            <p className="text-center text-lg font-bold text-gray-900">
              {vm.titleText}
            </p>
            <p className="text-center text-gray-600 pb-3 px-4">
              {vm.descriptionText}
            </p>
            <div className="flex justify-center px-24">
              <WithAnalytics
                event="$track_continue_checkin_link_clicked"
                params={{ button: 'Empty Growth Chart', source: 'growth_page' }}
              >
                {vm.checkinSlotContents}
              </WithAnalytics>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
