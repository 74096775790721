import * as React from 'react'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import { User } from 'store/modules/users'
import { CurrentPositionCard } from './current-position-card'
import { GoalPositionCard } from './goal-position-card'
import { ProgressArrow } from './progress-arrow'

type CareerPathSectionProps = {
  user: User
  className?: string
}

export const CareerPathSection = observer((props: CareerPathSectionProps) => {
  const { user, className } = props

  return (
    <section className={cn('flex flex-col gap-4 w-full', className)}>
      <h2 className="font-bold mb-0 text-theme-20 text-xl">Progression path</h2>
      <div className="flex flex-col md:flex-row items-center">
        <CurrentPositionCard
          user={user}
          className="shadow h-28 md:w-5/12 truncate"
        />
        <ProgressArrow className="drop-shadow text-theme-40 -mt-1.5 md:mt-0 md:-ml-1.5 transform rotate-90 md:rotate-0 max-w-[40px] min-h-[40px]" />
        <GoalPositionCard
          user={user}
          className="shadow h-28 md:w-8/12 truncate"
        />
      </div>
    </section>
  )
})
