import { store } from 'store/index'
import { successToast } from '../../utils/success-toast'
import { errorToast } from '../../utils/error-toast'
import { trackEvent } from '../../services/event-tracker'

export class GoalPositionStarVm {
  constructor(private positionId?: string, private userId?: string) {}

  get className() {
    return this.starred ? 'text-theme-50' : 'text-gray-600'
  }

  get tooltipContent() {
    if (this.starred) return 'Remove your starred position'
    if (this.replacing) return 'Replace your current starred position'

    return 'Set as your starred position'
  }

  get confirmationContent() {
    if (this.starred)
      return 'This will remove your existing starred position. Continue?'
    if (this.replacing)
      return 'This will replace your existing starred position. Continue?'

    return 'This will set your starred position. Continue?'
  }

  get confirmationCta() {
    if (this.starred) return 'Remove'
    if (this.replacing) return 'Replace'

    return 'Set'
  }

  get weight() {
    return this.starred ? 'fill' : 'bold'
  }

  get disabled() {
    return !this.userId || !this.positionId
  }

  get isCurrentPosition() {
    return store.users.byId(this.userId)?.position?.id === this.positionId
  }

  get requiresConfirmation() {
    return !!store.users.byId(this.userId)?.goalPosition
  }

  private get starred() {
    return store.users.byId(this.userId)?.goalPosition?.id === this.positionId
  }

  private get replacing() {
    if (store.users.byId(this.userId)?.goalPosition == null) return false

    return this.positionId !== store.users.byId(this.userId)?.goalPosition?.id
  }

  async onClick(source: string) {
    if (!this.userId) return

    const removing = this.starred
    const setting = !this.requiresConfirmation

    const result = await store.users.update(
      this.userId,
      { goalPosition: removing ? null : this.positionId },
      { source }
    )

    if (!result.success) {
      errorToast('Something went wrong. Please try again.')
      return
    }

    if (setting) {
      successToast(`Your starred position was set!`)
    } else if (removing) {
      successToast(`Your starred position was updated!`)
    } else {
      successToast(`Your starred position has been cleared.`)
    }

    if (setting || removing) {
      trackEvent('growth_profile_goal_selected', {
        goal: 'move-into-new-position',
        type: 'select-position',
      })
    } else {
      trackEvent('growth_profile_goal_selected', {
        goal: 'no-goal',
      })
    }
  }
}
