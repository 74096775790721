import * as React from 'react'
import { Link } from 'src/design-system'
import { CreateFormModalButton } from 'components/create-form-modal'
import { ArrowRight } from '@phosphor-icons/react'
import { store } from 'store/index'
import { User } from 'store/modules/users'
import { observer } from 'mobx-react-lite'
import { ActivityItem } from 'components/activity-feed/grid/types/activity-item'
import { Tab } from 'components/create-form'
import { GridContainer } from 'components/activity-feed/grid/container'

type LatestActivitySectionProps = {
  user: User
  showActionButton?: boolean
}

export const LatestActivitySection = observer(
  (props: LatestActivitySectionProps) => {
    const { user, showActionButton } = props

    const latestActivity = store.activities.filteredActivities(
      {
        userId: user.id,
        recordType: ['Action', 'FeedbackItem', 'Win', 'Checkin'],
      },
      3
    )

    const activities: Tab[] = ['win', 'feedback']
    if (showActionButton) activities.push('action')

    return (
      <section className="flex flex-col gap-4 w-full">
        <div className="flex justify-between">
          <h2 className="font-bold mb-0 text-gray-900 text-xl">
            Latest activity
          </h2>
          <CreateFormModalButton
            title="Add update"
            user={user}
            initialUserIds={[user.id]}
            tabs={activities}
            source="side_nav"
            className="bg-white text-theme-40 hover:text-theme-30"
            label="Add"
          />
        </div>
        <GridContainer>
          {store.activities.loading ? (
            <>
              <div className="h-40 bg-gray-50 rounded animate-pulse" />
              <div className="h-40 bg-gray-50 rounded animate-pulse animation-delay-150" />
              <div className="h-40 bg-gray-50 rounded animate-pulse animation-delay-300" />
            </>
          ) : (
            latestActivity.map((activity) => (
              <ActivityItem key={activity.id} activity={activity} />
            ))
          )}
        </GridContainer>
        <Link
          href={user.isCurrentUser ? '/feed?all=true' : `/?user_id=${user.id}`}
          underline={false}
          className="self-center"
          data-turbo-frame="content"
          data-turbo-action="advance"
        >
          All activity <ArrowRight />
        </Link>
      </section>
    )
  }
)
