import * as React from 'react'
import cn from 'classnames'
import { OptionProps, components } from 'react-select'
import { TeamOption } from '../team-select.types'

export const Option = (props: OptionProps<TeamOption>) => {
  const { children, isFocused, isSelected } = props

  return (
    <components.Option
      {...props}
      className={cn(
        isSelected && !isFocused && 'bg-theme-95',
        isFocused && 'bg-gray-50',
        'py-3 px-4 flex flex-row items-center gap-x-2 whitespace-nowrap text-gray-900'
      )}
    >
      <div>
        <span className="sr-only">Select </span>
        {children}
      </div>
    </components.Option>
  )
}
