import * as React from 'react'
import { CustomRadarDot } from 'components/skills-radar-chart/custom-radar-dot'
import { Position } from 'store/modules/positions'
import { sortAlphaNumberValues } from 'app/packs/src/utils/sort-helpers'
import { store } from 'store/index'
import { Team } from 'store/modules/teams'
import { User } from 'store/modules/users'
import {
  SkillsRadarChartDataPoint,
  SkillsRadarChartSeries,
} from '../../components/skills-radar-chart/skills-radar-chart.types'
import { Tag } from 'src/design-system'
import { Circle } from '@phosphor-icons/react'

export type CompareSkill = {
  id: string
  category: string | null
  level: number
  name: string
}

export class ComparePageVm {
  constructor(private positions: Position[], private user: User) {}

  colourOne = 'var(--theme-compliment-2)'
  colourTwo = 'var(--theme-compliment-4)'

  emptyStateBannerText(positionOneId?: string, positionTwoId?: string) {
    if (!this.hasPositions) return "You don't have a position to compare"

    const positionOneHasNoSkills =
      positionOneId !== undefined && this.skills(positionOneId).length === 0

    const positionTwoHasNoSkills =
      positionTwoId !== undefined && this.skills(positionTwoId).length === 0

    if (positionOneHasNoSkills || positionTwoHasNoSkills) {
      return "The position doesn't have any skills to compare"
    }
  }

  get hasPositions() {
    return this.positions.length > 0
  }

  label(positionId: string, colour: string, positionCode?: string) {
    return (
      <div className="flex flex-row gap-2 items-center">
        <Circle fill={colour} className="opacity-80" weight="fill" />
        {positionCode ? <Tag>{positionCode}</Tag> : null}
        {this.labelString(positionId)}
      </div>
    )
  }

  labelString(positionId?: string) {
    let string = ''
    if (positionId === 'your-shape') {
      string += 'You as'
      if (this.currentPosition?.name) string += ` ${this.currentPosition?.name}`
    } else {
      const position = this.positions.find(
        (position) => position.id === positionId
      )
      if (!position) return null
      string += position.name
      if (this.currentPosition?.id === position.id) string += ' (Your position)'
    }
    return string
  }

  options(colour: string) {
    const options: Record<
      string,
      {
        code: string
        label: React.ReactNode
        value: string
        labelString: string
      }[]
    > = {}

    if (this.checkin)
      options['You'] = [
        {
          code: '',
          label: this.label('your-shape', colour),
          labelString: this.labelString('your-shape') || '',
          value: 'your-shape',
        },
      ]

    this.sortedTeams.forEach((team) => {
      const teamPositions = this.positions.filter(
        (position) => position.team && position.team.id === team.id
      )

      teamPositions.forEach((position) => {
        position.disciplines.forEach((discipline) => {
          if (!discipline) return
          options[team.name] = options[team.name] || []

          const positionCode = position.positionCode(discipline.initials)

          options[team.name].push({
            code: positionCode,
            label: this.label(position.id, colour, positionCode),
            labelString: this.labelString(position.id) || '',
            value: position.id,
          })
        })
      })
    })

    return Object.entries(options).map(([teamName, options]) => ({
      label: teamName,
      options: options
        .sort((a, b) => sortAlphaNumberValues(a.code, b.code))
        .map((option) => {
          return {
            label: option.label,
            value: option.value,
            optionText: option.labelString,
          }
        }),
    }))
  }

  series(positionOneId?: string, positionTwoId?: string) {
    const series: SkillsRadarChartSeries = {
      positionOne: {
        colour: this.colourOne,
        dot: (chartData) => {
          const data = chartData.payload
          const payload = data.payload

          let colour = '#D9A817'
          if (payload.positionOne > payload.positionTwo || !payload.positionTwo)
            colour = '#267D50'
          if (payload.positionOne === payload.positionTwo) colour = '#38B776'

          return (
            <CustomRadarDot
              cx={data.x}
              cy={data.y}
              colour={colour}
              key={chartData.key}
            />
          )
        },
        skills: this.skills(positionOneId),
      },
    }

    if (positionTwoId) {
      series.positionTwo = {
        colour: this.colourTwo,
        skills: this.skills(positionTwoId),
        dot: (chartData) => {
          const data = chartData.payload

          return (
            <CustomRadarDot
              cx={data.x}
              cy={data.y}
              colour={this.colourTwo}
              key={chartData.key}
            />
          )
        },
      }
      return {
        positionTwo: series.positionTwo,
        positionOne: series.positionOne,
      }
    }

    return series
  }

  applyPositionChange(positionId: string) {
    this.fetchPositionDetails(positionId)
  }

  async fetchPositionDetails(positionId: string) {
    const id =
      positionId === 'your-shape' ? this.currentPosition?.id : positionId

    if (!id) return

    await store.positions.fetchOne(id, {
      include: [
        'skills',
        'skills.skill_variants',
        'skills.skill_variants.skill_levels',
        'skills.skill_variants.skill_levels.outcomes',
        'framework.frameworks_skills',
        'framework.frameworks_skills.category',
        'requirements',
      ],
    })
  }

  skills(positionId?: string): SkillsRadarChartDataPoint[] {
    if (!positionId) return []

    if (positionId === 'your-shape') return this.currentSkills

    const framework = store.positions.byId(positionId)?.framework
    if (!framework) return []

    return store.requirements.forPosition(positionId).map((req) => {
      const categoryName = store.frameworksSkills.forFrameworkAndSkill(
        framework.id,
        req.skill.id
      )?.category?.name

      return {
        id: req.skill.id,
        skillVariantId: req.skillVariant.id,
        category: categoryName || 'Uncategorised',
        level: req.level,
        name: req.skill.name,
      }
    })
  }

  private get currentPosition() {
    return this.user.position
  }

  private get sortedTeams() {
    return this.positions
      .reduce<Team[]>((teams, position) => {
        if (
          position.team &&
          !teams.find((team) => team.id === position.team.id)
        ) {
          teams.push(position.team)
        }

        return teams
      }, [])
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .sort((a, b) =>
        a.id === this.user.team?.id ? -1 : b.id === this.user.team?.id ? 1 : 0
      )
  }

  private get checkin() {
    return store.checkins.lastFinalisedUserCheckin(this.user.id)
  }

  private get currentSkills() {
    if (!this.checkin) return []
    return this.checkin.checkinSkills.reduce<SkillsRadarChartDataPoint[]>(
      (skills, checkinSkill) => {
        if (!this.currentPosition) return skills
        const requiredSkill = store.requirements
          .forPosition(this.currentPosition.id)
          .find((req) => req.skill.id === checkinSkill.skill.id)

        if (requiredSkill && checkinSkill.finalLevel !== null) {
          skills.push({
            id: checkinSkill.skill.id,
            skillVariantId: checkinSkill.associatedData.skill.skillVariantId,
            category: checkinSkill.categoryName || 'Uncategorised',
            level: checkinSkill.finalLevel,
            name: checkinSkill.skill.name,
          })
        }

        return skills
      },
      []
    )
  }
}
