import { observer } from 'mobx-react-lite'
import { Tag } from 'src/design-system'
import * as React from 'react'
import cn from 'classnames'
import { CheckinStatusLabel } from 'components/checkin-status-label'
import { FocusSkillStar } from 'components/focus-skill-star'
import { SkillListItemVm } from './skill-list-item-vm'
import { SOURCE } from 'pages/growth-profile-page/utils'
import { UpdateDonut } from 'components/update-donut'
import { User } from 'store/modules/users'
import { UserSkill } from 'store/modules/user_skills'
import styles from './skill-list-item.module.scss'
import { PackageProtect } from 'components/package-protect'
import { store } from 'store/index'

type SkillListItemProps = {
  user: User
  userSkill: UserSkill
  showDetails?: boolean
}

export const SkillListItem = observer((props: SkillListItemProps) => {
  const { user, userSkill, showDetails } = props

  const vm = React.useMemo(() => {
    return new SkillListItemVm(user, userSkill)
  }, [user, userSkill])

  const currentUser = store.nonNullCurrentUser
  const hasActiveSubscription = !!currentUser.org?.activeSubscription
  const isCurrentUser = user.isCurrentUser
  const isReport = currentUser.directReports.includes(user)

  return (
    <tr
      className={cn('h-px', isCurrentUser && 'cursor-pointer')}
      key={userSkill.id}
      onClick={() => {
        if (isCurrentUser && hasActiveSubscription && user.hasPackage('grow')) {
          vm.onClick()
        }
      }}
    >
      <Cell className="max-w-0 min-w-[200px] w-full">
        <div className="flex flex-col h-full md:pr-8 pr-4 py-5 w-full">
          <div className="flex flex-row gap-x-2 items-center mb-0">
            <span className="font-bold text-sm text-gray-900 truncate">
              {userSkill.skill.name}
            </span>
            {(isCurrentUser || isReport) && (
              <FocusSkillStar
                className={!userSkill.focused && !isCurrentUser ? 'hidden' : ''}
                editable={isCurrentUser && hasActiveSubscription}
                size={14}
                source={SOURCE}
                userSkill={userSkill}
              />
            )}
          </div>
          {userSkill.skill.description && (
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: userSkill.skill.description }}
            />
          )}
        </div>
      </Cell>
      {showDetails && (
        <>
          <Cell>
            <div className="flex flex-row gap-x-3 h-full items-center md:pr-8 pr-4 py-5">
              {typeof userSkill.positionRequirementLevel === 'number' && (
                <Tag variant="paper" className="py-0.5">
                  L{userSkill.positionRequirementLevel}
                </Tag>
              )}
              <PackageProtect>
                <CheckinStatusLabel status={userSkill.status} />
              </PackageProtect>
            </div>
          </Cell>
          <PackageProtect>
            <Cell>
              <div className="flex gap-1 h-full items-center md:pr-8 pr-4 py-5 text-xs">
                <UpdateDonut updateCounts={vm.updateCounts} />
              </div>
            </Cell>
          </PackageProtect>
        </>
      )}
    </tr>
  )
})

type CellProps = {
  children: React.ReactNode
  className?: string
}

const Cell = (props: CellProps) => {
  const { children, className } = props

  return (
    <td
      className={cn(
        'border-0 border-b border-solid border-gray-100 p-0',
        className
      )}
      style={{ height: 'inherit' }}
    >
      {children}
    </td>
  )
}
