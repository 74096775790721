import * as React from 'react'
import cn from 'classnames'
import { InputProps, components } from 'react-select'
import { TeamOption } from '../team-select.types'

export const Input = (props: InputProps<TeamOption>) => {
  const { inputClassName, isMulti, isDisabled } = props

  const hasValue = Array.isArray(props.selectProps.value)
    ? props.selectProps.value.length > 0
    : !!props.selectProps.value

  const showSeparator =
    hasValue && !props.selectProps.inputValue && !isDisabled && isMulti

  return (
    <div
      style={{ gridArea: '1/1/2/3' }}
      className={cn(inputClassName, 'relative text-red-500')}
    >
      {showSeparator && (
        <span className="absolute top-1/2 -translate-y-1/2 text-gray-400 cursor-text">
          and...
        </span>
      )}
      <components.Input
        {...props}
        className="text-sm ml-0"
        inputClassName="shadow-none"
      />
    </div>
  )
}
