import * as React from 'react'
import { IndicatorsContainerProps, components } from 'react-select'
import { TeamOption } from '../team-select.types'

export const IndicatorsContainer = (
  props: IndicatorsContainerProps<TeamOption>
) => (
  <components.IndicatorsContainer
    {...props}
    className="mt-1 h-8 flex items-center"
  />
)
