import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { User } from 'store/modules/users'
import { CareerPathSection } from '../career-path-section'
import { QuickActions, QuickActionsProps } from './quick-actions'
import { PackageProtect } from 'components/package-protect'
import { Inbox } from './inbox'
import { UserInfo } from './user-info'

type HeaderProps = {
  isLoading?: boolean
  user: User
  showEdit?: boolean
  showInbox?: boolean
  showPosition?: boolean
  showCareerPath?: boolean
  quickActionsProps?: Pick<
    QuickActionsProps,
    'showWin' | 'showFeedback' | 'showAction'
  >
}

export const Header = observer((props: HeaderProps) => {
  const {
    isLoading,
    user,
    showEdit,
    showInbox,
    showPosition,
    showCareerPath,
    quickActionsProps,
  } = props

  return (
    <header className="bg-gradient-to-b from-theme-90 to-theme-70">
      <div
        className={cn('max-w-[880px] mx-auto px-4 py-10 flex flex-col gap-8', {
          'mb-24': showCareerPath,
        })}
      >
        <div className="flex flex-col md:flex-row justify-between gap-8">
          <UserInfo
            isLoading={isLoading}
            user={user}
            showEdit={showEdit}
            showPosition={showPosition}
          />
          <div className="shrink-0 w-full md:w-2/4 flex flex-col gap-4 empty:hidden">
            <PackageProtect>
              {quickActionsProps && (
                <QuickActions
                  user={user}
                  className="justify-center md:justify-end"
                  showWin={quickActionsProps.showWin}
                  showFeedback={quickActionsProps.showFeedback}
                  showAction={quickActionsProps.showAction}
                />
              )}
              {showInbox && <Inbox user={user} />}
            </PackageProtect>
          </div>
        </div>
        {showCareerPath && (
          <CareerPathSection user={user} className="mt-auto md:-mb-32" />
        )}
      </div>
    </header>
  )
})
