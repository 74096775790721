import * as React from 'react'
import { SkillsRadarChartDataPoint } from './../../components/skills-radar-chart/skills-radar-chart.types'
import { Tag, useModalContext } from 'src/design-system'
import { Star } from '@phosphor-icons/react'
import { store } from 'store/index'
import { trackEvent } from '../../services/event-tracker'
import cn from 'classnames'
import { SkillDiffCategory } from './skill-diff-category'
import { Position } from 'store/modules/positions'
import { GoalPositionStar } from 'components/goal-position-star'
import {
  POSITION_MODAL_ID,
  PositionModalProps,
} from 'components/position-modal/utils'

export type SkillDiffSectionProps = {
  userPosition?: Position | null
  positionOne?: Position
  positionTwo?: Position
  positionOneSkills?: SkillsRadarChartDataPoint[]
  positionTwoSkills?: SkillsRadarChartDataPoint[]
}

export const SkillDiffSection: React.VFC<SkillDiffSectionProps> = ({
  userPosition,
  positionOne,
  positionTwo,
  positionOneSkills = [],
  positionTwoSkills = [],
}) => {
  const { openModal } = useModalContext()

  const positionOneSkillIds = React.useMemo(() => {
    return positionOneSkills.map((skill) => skill.id)
  }, [positionOneSkills])

  const positionTwoSkillIds = React.useMemo(() => {
    return positionTwoSkills.map((skill) => skill.id)
  }, [positionTwoSkills])

  const allSkillIds: string[] = React.useMemo(() => {
    return positionOneSkillIds.concat(
      positionTwoSkillIds.filter(
        (item) => positionOneSkillIds.indexOf(item) < 0
      )
    )
  }, [positionOneSkillIds, positionTwoSkillIds])

  const skillsWithDifference = positionTwoSkills.map((skill) => {
    const matchingSkill = positionOneSkills.find((s) => s.id === skill.id)
    const difference = matchingSkill ? skill.level - matchingSkill.level : null
    return { ...skill, difference }
  })

  const focusedSkillIdsForUser = allSkillIds.filter((skillId) =>
    store.userSkills
      .focusedSkillIdsForUser(store.nonNullCurrentUser.id)
      .includes(skillId)
  )

  const matchingSkillIds = skillsWithDifference
    .filter((skill) => {
      return skill?.difference === 0
    })
    .map((skill) => skill.id)

  const higherSkillIds = skillsWithDifference
    .filter((skill) => {
      if (!skill.difference) return
      return skill?.difference > 0
    })
    .map((skill) => skill.id)

  const lowerSkillIds = skillsWithDifference
    .filter((skill) => {
      if (!skill.difference) return
      return skill?.difference < 0
    })
    .map((skill) => skill.id)

  const notSharedSkillIds = positionOneSkillIds
    .filter((skillId) => !positionTwoSkillIds.includes(skillId))
    .concat(
      positionTwoSkillIds.filter(
        (skillId) => !positionOneSkillIds.includes(skillId)
      )
    )

  const notMatchingSkillIds = higherSkillIds
    .concat(lowerSkillIds)
    .concat(notSharedSkillIds)

  const [skillIdsToShow, setSkillIdsToShow] =
    React.useState<string[]>(allSkillIds)

  const positionOneSkillsToShow = positionOneSkills.filter((skill) => {
    return skillIdsToShow.includes(skill.id)
  })

  const positionTwoSkillsToShow = skillsWithDifference.filter((skill) => {
    return skillIdsToShow.includes(skill.id)
  })

  const groupByCategory = (skills: SkillsRadarChartDataPoint[]) => {
    return skills.reduce((acc, skill) => {
      const category = skill.category
      if (!acc[category]) {
        acc[category] = []
      }
      acc[category].push(skill)
      return acc
    }, {} as { [key: string]: SkillsRadarChartDataPoint[] })
  }

  const groupedPositionOneSkills = React.useMemo(() => {
    return groupByCategory(positionOneSkillsToShow)
  }, [positionOneSkillsToShow])

  const groupedPositionTwoSkills = React.useMemo(() => {
    return groupByCategory(positionTwoSkillsToShow)
  }, [positionTwoSkillsToShow])

  const positionOneCategories = positionOne?.framework?.sortedCategories
  const positionTwoCategories = positionTwo?.framework?.sortedCategories

  const combinedCategories = [
    ...(positionOneCategories || []),
    ...(positionTwoCategories || []),
  ]

  const uniqueCategories = combinedCategories.filter(
    (category, index, self) =>
      index === self.findIndex((c) => c.name === category.name)
  )

  const uniqueCategoryNames = uniqueCategories.map((category) => category.name)
  uniqueCategoryNames.push('Uncategorised')

  React.useEffect(() => {
    setSkillIdsToShow(allSkillIds)
  }, [allSkillIds])

  const [openedSkillId, setOpenedSkillId] = React.useState<
    string | undefined | null
  >(null)

  const [activeTabId, setActiveTabId] = React.useState('all')

  const onClickFilter = (skillIds: string[], tabId: string) => {
    setSkillIdsToShow(skillIds)
    setActiveTabId(tabId)
    trackEvent('$track_compare_filter_clicked', {
      tabId,
    })
  }

  const openPositionModal = (positionId: string) => {
    openModal<PositionModalProps>(POSITION_MODAL_ID, {
      positionId: positionId,
      source: 'manage-position-skills-banner',
    })
  }

  const loading = store.skills.loading || store.positions.loading

  return (
    <div className={cn(loading && 'opacity-50')}>
      <section className={'flex flex-row justify-center gap-5 flex-wrap'}>
        <div
          className={cn(
            'flex flex-row items-center gap-2 cursor-pointer p-1.5 rounded',
            {
              'bg-gray-100': activeTabId === 'all',
            }
          )}
          onClick={() => onClickFilter(allSkillIds, 'all')}
        >
          <span>All skills</span>
          <Tag variant="paper">{allSkillIds.length}</Tag>
        </div>
        <div
          className={cn(
            'flex flex-row items-center gap-2 cursor-pointer p-1.5 rounded',
            {
              'bg-gray-100': activeTabId === 'starred',
            }
          )}
          onClick={() => onClickFilter(focusedSkillIdsForUser, 'starred')}
        >
          <Star aria-hidden size={16} weight="fill" className="text-theme-50" />
          <span>Starred</span>
          <Tag variant="paper">{focusedSkillIdsForUser.length}</Tag>
        </div>

        <div
          className={cn(
            'flex flex-row items-center gap-2 cursor-pointer p-1.5 rounded',
            {
              'bg-gray-100': activeTabId === 'matching',
            }
          )}
          onClick={() => onClickFilter(matchingSkillIds, 'matching')}
        >
          <span className={'h-3 w-3 rounded-full mr-0 pr-0 bg-green-600'} />
          <span>Matching</span>
          <Tag variant="paper">{matchingSkillIds.length}</Tag>
        </div>

        <div
          className={cn(
            'flex flex-row items-center gap-2 cursor-pointer p-1.5 rounded',
            {
              'bg-gray-100': activeTabId === 'not-matching',
            }
          )}
          onClick={() => onClickFilter(notMatchingSkillIds, 'not-matching')}
        >
          <span className={'h-3 w-3 rounded-full mr-0 pr-0 bg-yellow-600'} />
          <span>Not matching</span>
          <Tag variant="paper">{notMatchingSkillIds.length}</Tag>
        </div>
      </section>

      <section>
        <div className="grid grid-cols-2 mx-16 my-8 py-4 text-xl">
          <div>
            {!positionOne && (
              <>
                <span className="font-bold">You</span>{' '}
                <span
                  className="text-gray-400 cursor-pointer"
                  onClick={() => {
                    if (userPosition) {
                      openPositionModal(userPosition.id)
                    }
                  }}
                >
                  as {userPosition?.name}
                </span>
              </>
            )}
            {positionOne && (
              <span
                className="font-bold cursor-pointer"
                onClick={() => {
                  if (positionTwo) {
                    openPositionModal(positionOne.id)
                  }
                }}
              >
                {positionOne.name}
              </span>
            )}
          </div>
          <div className="pl-8 flex items-center">
            <span
              className="font-bold mr-2 cursor-pointer"
              onClick={() => {
                if (positionTwo) {
                  openPositionModal(positionTwo.id)
                }
              }}
            >
              {positionTwo?.name}
            </span>{' '}
            <GoalPositionStar
              source={'compare-page'}
              positionId={positionTwo?.id}
              userId={store.nonNullCurrentUser.id}
            />
          </div>
        </div>
        {uniqueCategoryNames.length === 0 && !loading && (
          <SkillDiffEmptyState />
        )}
        {uniqueCategoryNames.map((category) => {
          return (
            <SkillDiffCategory
              key={`category-${category}`}
              category={category}
              positionOneSkills={groupedPositionOneSkills[category] || []}
              positionTwoSkills={groupedPositionTwoSkills[category] || []}
              openedSkillId={openedSkillId}
              setOpenedSkillId={setOpenedSkillId}
            />
          )
        })}
      </section>
    </div>
  )
}

const SkillDiffEmptyState = () => (
  <div className="flex flex-col items-center justify-center gap-4 pt-28">
    <div className="text-gray-600 text-lg font-bold">No skills to compare</div>
    <div className="text-gray-600 text-base">
      Select a different position to compare
    </div>
  </div>
)
