import * as React from 'react'
import { TabbedContent, Avatar, Button } from 'src/design-system'
import { avatarProps } from 'app/packs/src/utils/user-helpers'
import { QuickSearch } from 'components/quick-search'
import { Checks, ArrowRight } from '@phosphor-icons/react'
import pluralize from 'pluralize'
import { User } from 'store/modules/users'
import { store } from 'store/index'
import { InboxLinkCard } from './inbox-link-card'
import { InboxTab } from './inbox-tab'
import { observer } from 'mobx-react-lite'

export type InboxProps = {
  user: User
}

export const Inbox: React.VFC<InboxProps> = observer((props) => {
  const { user } = props
  const reports = user.directReports
  const actions = store.actions.incompleteForUser(user.id)
  const checkins = store.checkins.actionNeededFromUser(user.id)
  const currentUser = store.nonNullCurrentUser
  const skeleton = store.checkins.loading || store.actions.loading

  return (
    <TabbedContent.Root
      initialTabId={currentUser.hasPackage('grow') ? 'forYou' : 'reports'}
      className="flex gap-2"
    >
      <div className="flex items-start justify-between">
        <TabbedContent.Tabs className="gap-3">
          <InboxTab id="forYou">For you</InboxTab>
          {reports.length > 0 && <InboxTab id="reports">Reports</InboxTab>}
        </TabbedContent.Tabs>
        <QuickSearch
          label="Jump to..."
          icon={null}
          className="py-1 px-2 basis-3/12 border-theme-40 text-theme-40 hover:bg-theme-95"
        />
      </div>
      <TabbedContent.Content tabId="forYou">
        {skeleton ? (
          <div className="p-4 justify-center flex rounded bg-white/90 text-transparent animate-pulse">
            Loading...
          </div>
        ) : (
          <>
            <ul className="list-none p-0 flex flex-col gap-2">
              <li className="hidden only:block">
                <p className="p-4 rounded bg-white/90 text-gray-700 text-center text-sm">
                  Looking good for today ☕
                </p>
              </li>
              {actions.length > 0 && (
                <li>
                  <InboxLinkCard href={user.actionsUrl}>
                    <Checks className="flex-shrink-0" />
                    <span>
                      Review your {actions.length} open{' '}
                      <strong>{pluralize('Action', actions.length)}</strong>
                    </span>
                    <ArrowRight weight="bold" className="ml-auto" />
                  </InboxLinkCard>
                </li>
              )}
              {checkins.slice(0, actions.length > 0 ? 2 : 3).map((checkin) => (
                <li key={checkin.id}>
                  <InboxLinkCard href={checkin.path}>
                    <Avatar
                      size="xs"
                      src={checkin.author.avatarUrl}
                      fallbackText={checkin.author.initials}
                    />
                    <span>
                      {`Don't forget to review `}
                      <strong>
                        {checkin.author.isCurrentUser
                          ? `your `
                          : `${checkin.author.fullName}'s `}
                        Check-in
                      </strong>
                    </span>
                    <ArrowRight
                      weight="bold"
                      className="flex-shrink-0 ml-auto"
                    />
                  </InboxLinkCard>
                </li>
              ))}
            </ul>
            {checkins.length > 3 && (
              <Button
                as="a"
                className="mx-auto"
                href="/check-ins"
                data-turbo-frame="content"
                data-turbo-action="advance"
              >
                View all
              </Button>
            )}
          </>
        )}
      </TabbedContent.Content>
      {reports.length > 0 && (
        <TabbedContent.Content tabId="reports">
          {skeleton ? (
            <div className="p-4 justify-center flex rounded bg-white/90 text-transparent animate-pulse">
              Loading...
            </div>
          ) : (
            <>
              <ul className="list-none p-0 flex flex-col gap-2">
                {reports.slice(0, 3).map((report) => (
                  <li key={report.id}>
                    <InboxLinkCard href={report.profileUrl}>
                      <Avatar {...avatarProps(report)} size="xs" />
                      <span>{report.fullName}</span>
                      <ArrowRight
                        weight="bold"
                        className="flex-shrink-0 ml-auto"
                      />
                    </InboxLinkCard>
                  </li>
                ))}
              </ul>
              {reports.length > 3 && (
                <Button
                  as="a"
                  className="mx-auto"
                  href={`/users/${user.slug}/reports`}
                  data-turbo-frame="content"
                  data-turbo-action="advance"
                >
                  View all
                </Button>
              )}
            </>
          )}
        </TabbedContent.Content>
      )}
    </TabbedContent.Root>
  )
})
