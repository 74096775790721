import * as React from 'react'
import cn from 'classnames'
import { Checks, CircleWavyCheck, Megaphone } from '@phosphor-icons/react'
import { CreateFormModalButton } from 'components/create-form-modal'
import { User } from 'store/modules/users'
import { SOURCE } from '../utils'

export type QuickActionsProps = {
  user: User
  className?: string
  showWin?: boolean
  showFeedback?: boolean
  showAction?: boolean
}

export const QuickActions = (props: QuickActionsProps) => {
  const { user, className, showWin, showFeedback, showAction } = props

  return (
    <div className={cn('flex items-center gap-1 empty:hidden', className)}>
      {showWin && (
        <CreateFormModalButton
          source={SOURCE}
          tabs={['win']}
          label={
            <div className="flex items-center gap-0.5 px-1">
              <CircleWavyCheck weight="bold" /> Win
            </div>
          }
          initialUserIds={[user.id]}
          variant="outline"
        />
      )}
      {showAction && (
        <CreateFormModalButton
          source={SOURCE}
          tabs={['action']}
          label={
            <div className="flex items-center gap-0.5 px-1">
              <Checks weight="bold" /> Action
            </div>
          }
          user={user}
          variant="outline"
        />
      )}
      {showFeedback && (
        <CreateFormModalButton
          source={SOURCE}
          tabs={['feedback']}
          label={
            <div className="flex items-center gap-0.5 px-1">
              <Megaphone weight="bold" /> Feedback
            </div>
          }
          initialUserIds={[user.id]}
          variant="outline"
        />
      )}
    </div>
  )
}
