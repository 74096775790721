import * as React from 'react'

type ProgressArrowProps = {
  className?: string
}

export const ProgressArrow = ({ className }: ProgressArrowProps) => (
  <svg
    width="47"
    height="16"
    viewBox="0 0 47 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.666667 8C0.666667 10.9455 3.05448 13.3333 6 13.3333C8.94552 13.3333 11.3333 10.9455 11.3333 8C11.3333 5.05448 8.94552 2.66667 6 2.66667C3.05448 2.66667 0.666667 5.05448 0.666667 8ZM46.7071 8.70711C47.0976 8.31658 47.0976 7.68342 46.7071 7.29289L40.3431 0.928932C39.9526 0.538408 39.3195 0.538408 38.9289 0.928932C38.5384 1.31946 38.5384 1.95262 38.9289 2.34315L44.5858 8L38.9289 13.6569C38.5384 14.0474 38.5384 14.6805 38.9289 15.0711C39.3195 15.4616 39.9526 15.4616 40.3431 15.0711L46.7071 8.70711ZM6 9H8V7H6V9ZM12 9H16V7H12V9ZM20 9H24V7H20V9ZM28 9H32V7H28V9ZM36 9H40V7H36V9ZM44 9H46V7H44V9Z"
      fill="currentColor"
    />
  </svg>
)
