import { Link } from 'src/design-system'
import * as React from 'react'
import { SkillsSectionEmptyStateVm } from './skills-section-empty-state-vm'
import { User } from 'store/modules/users'
import { WithAnalytics } from 'components/with-analytics'

export type SkillsSectionEmptyStateProps = {
  user: User
}

export const SkillsSectionEmptyState: React.VFC<
  SkillsSectionEmptyStateProps
> = (props) => {
  const { user } = props

  const vm = React.useMemo(() => new SkillsSectionEmptyStateVm(user), [user])

  return (
    <tr>
      <td className="text-center py-12 md:py-16" colSpan={5}>
        <div className="text-gray-500 text-sm">
          {vm.text}
          {vm.canEditFramework && (
            <WithAnalytics
              event="$track_growth_profile_page_skills_section_empty_add_required_skills"
              params={{
                context: user.isCurrentUser
                  ? 'Own growth profile page'
                  : "Report's growth profile page",
              }}
            >
              <Link
                className="p-0 ml-1"
                href={vm.frameworkPath}
                data-turbo-frame="content"
                data-turbo-action="advance"
              >
                Add some
              </Link>
            </WithAnalytics>
          )}
        </div>
      </td>
    </tr>
  )
}
