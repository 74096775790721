import { Link, Loader } from 'src/design-system'
import * as SecondaryNav from 'components/secondary-nav'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { ComparePageVm } from './compare-page-vm'
import { SkillsRadarChart } from 'components/skills-radar-chart'
import { ComparePositionSelect } from './compare-position-select'
import { SkillDiffSection } from './skill-diff-section'
import { HoverCard } from './hover-card'
import { Tooltip } from 'recharts'
import { trackEvent } from '../../services/event-tracker'
import { ArrowsLeftRight, CaretLeft } from '@phosphor-icons/react'

type ComparePageParams = {
  defaultPositionOneId?: string
  defaultPositionTwoId?: string
  source?: string
}

export const ComparePage = observer((props: ComparePageParams) => {
  const { defaultPositionOneId, defaultPositionTwoId, source } = props

  const user = store.nonNullCurrentUser

  const positionSelectPositions = store.positions.forOrg

  const latestCheckin = store.checkins.lastFinalisedUserCheckin(user.id)

  const [positionOneId, setPositionOneId] = React.useState<string | undefined>(
    latestCheckin ? defaultPositionOneId : user.position?.id
  )

  const [positionTwoId, setPositionTwoId] = React.useState<string | undefined>(
    defaultPositionTwoId
  )

  const vm = React.useMemo(() => {
    return new ComparePageVm(positionSelectPositions, user)
  }, [positionSelectPositions, user])

  React.useEffect(() => {}, [defaultPositionOneId, defaultPositionTwoId])

  React.useEffect(() => {
    // via growth page, compare "me" to the given position
    if (!defaultPositionOneId && defaultPositionTwoId) {
      setPositionOneId(latestCheckin ? 'your-shape' : user.position?.id)
      setPositionTwoId(defaultPositionTwoId)
    }

    // via fw pages, compare the given position, but let the user choose the 2nd pos
    if (defaultPositionOneId && !defaultPositionTwoId) {
      setPositionOneId(defaultPositionOneId)
    }

    // via the main nav, set "me" as the first, and choose a sensible default for the 2nd
    if (!defaultPositionOneId && !defaultPositionTwoId) {
      setPositionOneId(latestCheckin ? 'your-shape' : user.position?.id)

      setPositionTwoId(
        user.goalPosition?.id ||
          user.suggestedGoalPosition?.id ||
          user.position?.id
      )
    }
  }, [
    defaultPositionOneId,
    defaultPositionTwoId,
    latestCheckin,
    user.goalPosition?.id,
    user.position?.id,
    user.suggestedGoalPosition?.id,
  ])

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchPositions = async () => {
        if (positionOneId) {
          vm.fetchPositionDetails(positionOneId)
        }
        if (positionTwoId) {
          vm.fetchPositionDetails(positionTwoId)
        }
      }

      const fetchLastFinalisedCheckin = async () => {
        await store.checkins.fetchAll({
          filter: { author_id: user.id, status: 'finalised' },
          include: ['checkin_skills', 'checkin_skills.skill'],
          page: { size: 1 },
        })
      }

      await Promise.all([fetchPositions(), fetchLastFinalisedCheckin()])
    }
    fetchData()
  }, [positionOneId, positionTwoId, user.id, vm])

  const positionOne = store.positions.byId(positionOneId)
  const positionTwo = store.positions.byId(positionTwoId)

  const trackCompareEvent = () => {
    if (positionOneId && positionTwoId) {
      trackEvent('$track_positions_compared', {
        source: source,
        pos_1_fw_id: positionOne?.framework?.id,
        pos_1_id: positionOneId,
        pos_1_level: positionOne?.seniorityLevel,
        pos_1_name: positionOne?.name,
        pos_2_fw_id: positionTwo?.framework?.id,
        pos_2_id: positionTwoId,
        pos_2_level: positionTwo?.seniorityLevel,
        pos_2_name: positionTwo?.name,
      })
    }
  }

  return (
    <div className="pb-24">
      <SecondaryNav.Root>
        <div className="flex flex-col">
          <div className="flex flex-row gap-2">
            <SecondaryNav.Header>
              <Link
                href={user.profileUrl}
                data-turbo-frame="content"
                data-turbo-action="advance"
              >
                <CaretLeft weight="bold" className="text-gray-600" />
              </Link>
              Compare positions
            </SecondaryNav.Header>
          </div>
        </div>
      </SecondaryNav.Root>
      <section className="bg-gray-50 flex flex-col items-center gap-12 justify-center w-full mb-10 p-10">
        <div className="flex flex-col xl:flex-row gap-2 xl:gap-10 w-full justify-center items-center">
          <div className="flex flex-col gap-2">
            <div className="font-bold">Compare</div>
            <ComparePositionSelect
              value={positionOneId}
              onChange={(positionId) => {
                if (positionId) {
                  setPositionOneId(positionId)
                  vm.applyPositionChange(positionId)
                  trackCompareEvent()
                }
              }}
              options={vm.options(vm.colourOne)}
            />
          </div>
          <ArrowsLeftRight
            className="h-4 w-4 cursor-pointer xl:self-end mt-4 xl:mt-0 xl:mb-4 text-gray-600 hover:text-green-600 flex-shrink-0"
            weight="bold"
            onClick={() => {
              const tempPositionOneId = positionOneId
              setPositionOneId(positionTwoId)
              setPositionTwoId(tempPositionOneId)
            }}
          />
          <div className="flex flex-col gap-2">
            <div className="font-bold">To</div>
            <ComparePositionSelect
              value={positionTwoId}
              onChange={(positionId) => {
                if (positionId) {
                  setPositionTwoId(positionId)
                  vm.applyPositionChange(positionId)
                  trackCompareEvent()
                }
              }}
              options={vm.options(vm.colourTwo)}
            />
          </div>
        </div>
        <div className="min-h-[325px]">
          {store.positions.loading ? (
            <div className="flex h-full w-full items-center justify-center">
              <Loader />
            </div>
          ) : (
            <SkillsRadarChart
              series={vm.series(positionOneId, positionTwoId)}
              size={300}
            >
              <Tooltip
                content={
                  <HoverCard
                    positionOneLabel={vm.labelString(positionOneId)}
                    positionTwoLabel={vm.labelString(positionTwoId)}
                  />
                }
                cursor={{ fill: 'transparent' }}
                position={{ x: -10, y: 280 }}
                wrapperStyle={{ zIndex: 10 }}
              />
            </SkillsRadarChart>
          )}
        </div>
      </section>
      <SkillDiffSection
        userPosition={user.position}
        positionOne={positionOne}
        positionTwo={positionTwo}
        positionOneSkills={vm.skills(positionOneId)}
        positionTwoSkills={vm.skills(positionTwoId)}
      />
    </div>
  )
})
