import * as React from 'react'
import { Avatar, Link, Tag, Tooltip, useModalContext } from 'src/design-system'
import { openModal as openRailsModal } from '../../../utils/open-modal'
import { avatarProps } from 'app/packs/src/utils/user-helpers'
import {
  PencilSimple,
  IdentificationBadge,
  UsersThree,
  Users,
} from '@phosphor-icons/react'
import {
  POSITION_MODAL_ID,
  PositionModalProps,
} from 'components/position-modal/utils'
import { User } from 'store/modules/users'
import { SOURCE } from '../utils'
import { store } from 'store/index'

export type UserInfoProps = {
  user: User
  isLoading?: boolean
  showEdit?: boolean
  showPosition?: boolean
}

export const UserInfo: React.VFC<UserInfoProps> = (props) => {
  const { user, isLoading, showEdit, showPosition } = props

  const { openModal } = useModalContext()

  const manager = user.manager
  const position = user.position
  const team = user.team

  const hasActiveSubscription =
    !!store.nonNullCurrentUser.org?.activeSubscription

  const onPositionClick = () => {
    if (!position) return

    openModal<PositionModalProps>(POSITION_MODAL_ID, {
      positionId: position.id,
      source: SOURCE,
    })
  }

  const editPosition = () => {
    openRailsModal(`/org_users/${user.id}/edit?find_by_user_id=true`)
  }

  return (
    <div className="flex gap-4 truncate overflow-visible">
      <Avatar
        {...avatarProps(user)}
        size="lg"
        className="border-2 border-solid border-white shadow-md m-1"
      />
      <div className="flex flex-col gap-4 whitespace-nowrap truncate">
        <div className="flex gap-2 items-center">
          <h1 className="font-bold text-3xl text-theme-20 truncate">
            {user.isCurrentUser ? `Hi, ${user.fname}` : user.fullName}
          </h1>
          {team && team.hasEditor(user.id) && <Tag variant="paper">Editor</Tag>}
          {showEdit && (
            <button
              type="button"
              onClick={editPosition}
              aria-label="Edit profile"
              className="transition-colors text-theme-40 hover:text-theme-30"
            >
              <PencilSimple className="shrink-0" weight="bold" />
            </button>
          )}
        </div>
        <div className="flex flex-col gap-1">
          {showPosition && (
            <Tooltip
              content="Position"
              className="w-fit text-theme-40 flex gap-1.5 items-center"
            >
              <IdentificationBadge className="shrink-0" weight="bold" />
              {hasActiveSubscription && position && (
                <button
                  className="p-0 font-bold truncate transition-colors hover:text-theme-30"
                  onClick={onPositionClick}
                >
                  {position.name}
                </button>
              )}
              {!hasActiveSubscription && position && (
                <span className="text-gray-900">{position.name}</span>
              )}
              {!position && <span className="text-gray-900">No Position</span>}
            </Tooltip>
          )}
          <Tooltip
            content="Team"
            className="w-fit text-theme-40 flex gap-1.5 items-center"
          >
            <UsersThree className="shrink-0" weight="bold" />
            {team ? (
              <Link
                className="p-0 font-bold truncate hover:text-theme-30"
                data-turbo="false"
                href={team.frameworkPath}
                underline={false}
              >
                {team.name}
              </Link>
            ) : (
              <span className="text-gray-900">No team</span>
            )}
          </Tooltip>
          <Tooltip
            content="Manager"
            className="w-fit text-theme-40 flex gap-1.5 items-center"
          >
            <Users className="shrink-0" weight="bold" />
            {manager ? (
              <Link
                className="p-0 font-bold truncate hover:text-theme-30"
                data-turbo="false"
                href={manager.profileUrl}
                underline={false}
              >
                {manager.fullName}
              </Link>
            ) : (
              <p className="mb-0 text-gray-900">No manager</p>
            )}
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
