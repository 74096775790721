import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  isSameDay,
  isSameMonth,
} from 'date-fns'
import { store } from 'store/index'
import { dateRange } from '../../utils/date-helpers'
import {
  emptyStateDomainBounds,
  growthChartEmptyData,
  CheckinPointOverTime,
  GrowthOverTimeGraphData,
} from './empty-data'

type GraphBuildOption = {
  rangeStep: 'month' | 'day'
  amount: number
  domainMinimum: number
  domainMaximum: number
  leftPad: number
  rightPad: number
  graphKeyFormat: string
}

type TimePeriodOptionsMap = {
  [key: string]: GraphBuildOption
}

type CheckinOverTimeData = {
  name: string
  skillName: string | null
  data: CheckinPointOverTime[]
}

export const colors: Record<string, string> = {
  working_towards: 'text-yellow-600',
  meeting: 'text-green-600',
  exceeding: 'text-green-800',
  created: 'text-gray-600',
}

export class GrowthOverTimeVm {
  constructor(private timePeriod: string, private userId: string) {}

  timePeriodOptions(timePeriod: string): GraphBuildOption {
    const allTimeMonths = differenceInCalendarMonths(
      new Date(),
      this.userCreatedAt
    )

    const allTimeDays = differenceInCalendarDays(new Date(), this.userCreatedAt)

    const lastCheckinDate = store.checkins.lastFinalisedUserCheckin(
      this.userId
    )?.finalisedAt

    const sinceLastCheckinMonths = differenceInCalendarMonths(
      new Date(),
      lastCheckinDate || new Date()
    )

    const chartWidth = 420

    const timePeriodOptions: TimePeriodOptionsMap = {
      '12 months': {
        rangeStep: 'month',
        amount: allTimeMonths + 1,
        domainMinimum: allTimeMonths - 11,
        domainMaximum: allTimeMonths,
        leftPad: chartWidth / 12,
        rightPad: chartWidth / 12,
        graphKeyFormat: 'MMM',
      },
      '6 months': {
        rangeStep: 'month',
        amount: allTimeMonths + 1,
        domainMinimum: allTimeMonths - 5,
        domainMaximum: allTimeMonths,
        leftPad: chartWidth / 6,
        rightPad: chartWidth / 6,
        graphKeyFormat: 'MMM',
      },
      '3 months': {
        rangeStep: 'month',
        amount: allTimeMonths + 1,
        domainMinimum: allTimeMonths - 2,
        domainMaximum: allTimeMonths,
        leftPad: chartWidth / 3,
        rightPad: chartWidth / 3,
        graphKeyFormat: 'MMM',
      },
      '30 days': {
        rangeStep: 'day',
        amount: allTimeDays + 1,
        domainMinimum: allTimeDays - 29,
        domainMaximum: allTimeDays,
        leftPad: chartWidth / 30,
        rightPad: chartWidth / 30,
        graphKeyFormat: 'yyyy-MM-dd',
      },
      'all time': {
        rangeStep: 'month',
        amount: allTimeMonths + 1,
        domainMinimum: 0,
        domainMaximum: allTimeMonths,
        leftPad: chartWidth / (allTimeMonths + 1),
        rightPad: chartWidth / (allTimeMonths + 1),
        graphKeyFormat: 'MMM',
      },
      'last checkin': {
        rangeStep: 'month',
        amount: allTimeMonths + 1,
        domainMinimum: allTimeMonths - sinceLastCheckinMonths,
        domainMaximum: allTimeMonths + 1,
        leftPad: chartWidth / allTimeMonths,
        rightPad: 0,
        graphKeyFormat: 'MMM-yyyy',
      },
    }
    return timePeriodOptions[timePeriod]
  }

  get chartData(): GrowthOverTimeGraphData[] {
    if (this.showEmptyState) return growthChartEmptyData

    // the generation of the chart data is split into 2 steps:

    /**
     * step 1: build an array of "steps" (months or days) as defined in
     * timePeriodOptions. we build from the users createdAt date, to today
     * either in days or months, and for each step, figure out what
     * checkin happened in that step.
     *
     * we do the above for each skill the user has checkin in against.
     * this is where the filtering of skills happens
     */
    const data = this.buildCheckinDataOverTime

    /**
     * step 2: break the list of steps into separate line points, so that
     * they can be plotted.
     *
     * e.g. a line with 4 points at [0, 3, 7, 10] gets transformed to a group of
     * pairs which can be plotted:
     *
     * [
     *  [0,3]
     *  [3,7]
     *  [7,10]
     * ]
     *
     * each point has additional data about it's color and style that is used
     * when plotted in recharts
     */
    return this.splitDataOverTimeIntoLines(data)
  }

  get buildCheckinDataOverTime(): CheckinOverTimeData[] {
    const range = dateRange(
      this.buildOptions.rangeStep,
      this.buildOptions.amount
    )

    const step = this.buildOptions.rangeStep

    // builds a complete list of skills a user has checked-in against
    const userCheckinSkills = store.checkins
      .forAuthor(this.userId)
      .flatMap((checkin) => {
        return checkin.checkinSkills.map((checkinSkill) => {
          return {
            id: checkinSkill.skill.id,
            key: this.checkinNameToKey(checkinSkill.skill.name),
            name: checkinSkill.skill.name,
          }
        })
      })

    // a user may have checked in against the same skill multiple times, so
    // we need to remove duplicates here:
    const uniqueFilteredCheckinSkills = [
      ...new Map(userCheckinSkills.map((item) => [item['key'], item])).values(),
    ]

    // for all the unqiue checkin skills a user has
    return uniqueFilteredCheckinSkills.map((checkinSkill) => {
      const checkinSkillKey = checkinSkill.key

      // for each date in the range
      const timeRangeData = range.map((date, index) => {
        // find the associated checkin for the date step we are looking at
        const checkinsForPeriod = this.checkinsForPeriod(date, step)

        let checkinSkillInThisPeriod = null

        // if we have checkins in this time period, find the skill we are currently iterating on
        if (checkinsForPeriod.length > 0) {
          // TODO figure out a better way than just getting first element in array ... order by fianlsied at?
          checkinSkillInThisPeriod = checkinsForPeriod[0].checkinSkills.find(
            (checkinSkillInPeriod) => {
              return checkinSkill.id == checkinSkillInPeriod.skill.id
            }
          )
        }

        // return the checkin information for the current step in the data range
        return {
          category: index,
          value: checkinSkillInThisPeriod?.finalLevel || null,
          name: checkinSkill.name,
          status: checkinSkillInThisPeriod?.status || null,
          checkinId: checkinsForPeriod ? checkinsForPeriod[0]?.id : null,
        }
      })

      // return a checkin skill with a data key which is the data-over-time
      return {
        name: checkinSkillKey,
        skillName: checkinSkill.name,
        data: timeRangeData,
      }
    })
  }

  get referenceLines() {
    if (this.showEmptyState) return []

    const range = dateRange(
      this.buildOptions.rangeStep,
      this.buildOptions.amount
    )

    const step = this.buildOptions.rangeStep

    return range.reduce<{ labels: string[]; stroke: string; x: number }[]>(
      (lines, date, index) => {
        const positionsForPeriod = this.positionsForPeriod(date, step)

        if (positionsForPeriod.length > 0) {
          lines.push({
            labels: positionsForPeriod.map((position) => position.name),
            stroke: index === 0 ? '#0000000' : '#E5E5E5',
            x: index,
          })
        }

        return lines
      },
      []
    )
  }

  splitDataOverTimeIntoLines = (
    data: CheckinOverTimeData[]
  ): GrowthOverTimeGraphData[] => {
    return data.flatMap((completePlotLine) => {
      // build a list of all the points we need to plot lines
      const checkinIndexes = completePlotLine.data
        .map((plotPoint, index) => {
          if (plotPoint.status !== null) return index
        })
        .filter((item): item is number => item !== undefined)

      const plots = [0, ...checkinIndexes]

      // put the x-axis plots into an array of pairs
      const groups = this.slidingGroups(plots)

      // for each pair of points, return an array of points with
      // the associated color / skillName information
      const newPlotLine = groups.map((pointPair, index) => {
        const firstPoint = pointPair[0] === 0

        // point1 of the firstPoint on the graph gets forced to 0
        let point1 = firstPoint
          ? { ...completePlotLine.data[pointPair[0]], value: 0 }
          : completePlotLine.data[pointPair[0]]

        let point2 = completePlotLine.data[pointPair[1]]

        const color = firstPoint
          ? colors['created']
          : colors[point1.status || 'created']

        // pad out values if either is null
        if (point1.value === null) point1 = { ...point1, value: point2.value }
        if (point2.value === null) point2 = { ...point2, value: point1.value }

        return {
          name: `${completePlotLine.name}#${index}`,
          skillName: completePlotLine.skillName,
          color: color,
          dashed: firstPoint, // if the first element of the point pair is 0 (createdAt), the line is dashed
          data: [point1, point2],
        }
      })

      return newPlotLine
    })
  }

  slidingGroups(plots: number[]): Array<Array<number>> {
    return plots.slice(1).map((a, i) => [plots[i], a])
  }

  get domainMinimum(): number {
    if (this.showEmptyState)
      return emptyStateDomainBounds[this.timePeriod].domainMinimum

    return this.buildOptions.domainMinimum
  }

  get domainMaximum(): number {
    if (this.showEmptyState)
      return emptyStateDomainBounds[this.timePeriod].domainMaximum

    return this.buildOptions.domainMaximum
  }

  get yAxisMax(): number {
    return (
      this.chartData.reduce<number>((max, line) => {
        const lineMax = line.data.reduce<number>((max, point) => {
          return Math.max(max, point.value ?? 0)
        }, 0)

        return Math.max(max, lineMax)
      }, 0) + 2
    )
  }

  get leftPad(): number {
    return this.buildOptions.leftPad
  }

  get rightPad(): number {
    return this.buildOptions.rightPad
  }

  checkinNameToKey = (name: string): string => {
    return name.toLocaleLowerCase().replaceAll(' ', '_').replaceAll('/', '')
  }

  happendInTimeFrame = (
    happenedOn: Date | null,
    compareOn: string,
    forDate: Date
  ): boolean => {
    if (!happenedOn) return false

    if (compareOn === 'month') {
      return isSameMonth(happenedOn, forDate)
    }
    if (compareOn === 'day') {
      return isSameDay(happenedOn, forDate)
    }

    return false
  }

  checkinsForPeriod(forDate: Date, compareOn: 'day' | 'month') {
    if (!this.userId) return []

    return store.checkins.forAuthor(this.userId).filter((checkin) => {
      return this.happendInTimeFrame(checkin.finalisedAt, compareOn, forDate)
    })
  }

  get showEmptyState(): boolean {
    return this.numberOfCompleteCheckins === 0
  }

  private get buildOptions() {
    return this.timePeriodOptions(this.timePeriod)
  }

  private get numberOfCompleteCheckins() {
    if (!this.userId) return []

    return store.checkins
      .forAuthor(this.userId)
      .filter((checkin) => checkin.isFinalised).length
  }

  private get positions() {
    const positionChanges = store.positionChanges.sortedForUser(this.userId)
    const positions = []

    if (positionChanges.length === 0) {
      if (this.user?.position) {
        positions.push({
          date: this.userCreatedAt,
          name: this.user.position.name,
        })
      }
    } else {
      positionChanges.forEach((positionChange, index) => {
        if (index === 0 && positionChange.positionFromName) {
          positions.push({
            date: this.userCreatedAt,
            name: positionChange.positionFromName,
          })
        }

        if (positionChange.positionToName) {
          positions.push({
            date: positionChange.createdAt,
            name: positionChange.positionToName,
          })
        }
      })
    }

    if (positions.length > 0) {
      const currentPosition = positions[positions.length - 1]

      positions[positions.length - 1] = {
        date: currentPosition.date,
        name: `🚀 ${currentPosition.name}`,
      }
    }

    return positions
  }

  private get user() {
    return store.users.byId(this.userId)
  }

  private get userCreatedAt() {
    return this.user?.createdAt || new Date('2017-01-01')
  }

  private positionsForPeriod(forDate: Date, compareOn: 'day' | 'month') {
    return this.positions.filter((position) => {
      return this.happendInTimeFrame(position.date, compareOn, forDate)
    })
  }
}
