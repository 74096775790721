import * as React from 'react'
import {
  components,
  SingleValueProps as BaseSingleValueProps,
} from 'react-select'
import { TeamOption } from '../team-select.types'

type SingleValueProps = BaseSingleValueProps<TeamOption>

export const SingleValue = (props: SingleValueProps) => {
  const { children } = props

  return (
    <components.SingleValue
      {...props}
      className="flex flex-row items-center gap-x-1"
    >
      {children}
    </components.SingleValue>
  )
}
