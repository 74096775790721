import { Checks, CircleWavyCheck, Megaphone, Note } from '@phosphor-icons/react'
import * as React from 'react'
import { store } from 'store/index'
import { Skill } from 'store/modules/skills'
import { User } from 'store/modules/users'

export type ActivityCountsProps = {
  user: User
  skill: Skill
}

export const ActivityCounts = (props: ActivityCountsProps) => {
  const { user, skill } = props

  const winsCount = store.wins.forSkillAndWinner(skill.id, user.id).length
  const actionsCount = store.actions.forSkillAndUser(skill.id, user.id).length
  const notesCount = store.wins.notesForSkillsAndUsers(
    [skill.id],
    [user.id]
  ).length
  const checkinsCount = store.checkins
    .finalisedForAuthor(user.id)
    .filter((checkin) =>
      checkin.checkinSkills.some(
        (checkinSkill) => checkinSkill.skill.id === skill.id
      )
    ).length

  return (
    <article className="flex gap-3">
      <span className="flex gap-1 items-center">
        <CircleWavyCheck weight="bold" className="text-green-400 " />{' '}
        {winsCount}
      </span>
      <span className="flex gap-1 items-center">
        <Checks weight="bold" className="text-green-600" />
        {actionsCount}
      </span>
      <span className="flex gap-1 items-center">
        <Note weight="bold" className="text-blue-600" />
        {notesCount}
      </span>
      <span className="flex gap-1 items-center">
        <Megaphone weight="bold" className="text-yellow-600" />
        {checkinsCount}
      </span>
    </article>
  )
}
