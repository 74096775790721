import { SOURCE } from '../../utils'
import { store } from 'store/index'
import { User } from 'store/modules/users'
import { UserSkill } from 'store/modules/user_skills'

export class SkillListItemVm {
  constructor(private user: User, private userSkill: UserSkill) {}

  get actionProgressPercent() {
    if (this.actions.length > 0) {
      return (
        (this.actions.filter((action) => action.completed).length /
          this.actions.length) *
        100
      )
    } else {
      return 100
    }
  }

  get actionStatus() {
    return `${this.actions.filter((action) => action.completed).length}/${
      this.actions.length
    }`
  }

  get updateCounts() {
    const actionsCompletedCount = store.actions.completedSinceDateTime(
      'all time',
      { skillId: this.skillId, userIds: [this.user.id] }
    ).length

    const feedbackReceivedCount = store.feedbackItems.forSkillsAndUsers(
      [this.skillId],
      [this.user.id]
    ).length

    const notesCount = store.wins.notesForSkillsAndUsers(
      [this.skillId],
      [this.user.id]
    ).length

    const winsReceivedCount = store.wins.forSkillsAndWinners(
      [this.skillId],
      [this.user.id]
    ).length

    return {
      actionsCompletedCount,
      feedbackReceivedCount,
      notesCount,
      winsReceivedCount,
    }
  }

  onClick() {
    const event = new CustomEvent('updatesdrawer:open', {
      detail: {
        id: this.skillId,
        userId: this.user.id,
        name: this.userSkill.skill.name,
        source: SOURCE,
      },
    })

    document.dispatchEvent(event)
  }

  private get actions() {
    return store.actions.forSkillAndUser(this.userSkill.skill.id, this.user.id)
  }

  private get skillId() {
    return this.userSkill.skill.id
  }
}
