import { Team } from 'store/modules/teams'

export class TeamSelectVm {
  constructor(private teams: Team[], private multi: boolean = false) {}

  getOption(value: string | string[] | null | undefined) {
    if (!this.multi) {
      return this.options.find((option) => option.value === value) || null
    }

    const filteredOptions = this.options.filter((option) =>
      value?.includes(option.value)
    )

    return filteredOptions.length > 0 ? filteredOptions : null
  }

  get options() {
    return this.teams
      .map((team) => ({
        label: team.name,
        value: team.id,
        team,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }
}
