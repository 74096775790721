import { observer } from 'mobx-react-lite'
import {
  GlobalConfirmationDialog,
  Tooltip,
  useConfirmationDialogContext,
} from 'src/design-system'
import * as React from 'react'
import cn from 'classnames'
import { GoalPositionStarVm } from './goal-position-star-vm'
import { Star } from '@phosphor-icons/react'
import { ConfirmationDialogProps } from '../../design-system/components/molecules/ConfirmationDialog'

type GoalPositionStarProps = {
  className?: string
  size?: number
  source: string
  positionId?: string
  userId?: string
}

export const GoalPositionStar: React.VFC<GoalPositionStarProps> = observer(
  (props) => {
    const { className, size = 14, source, positionId, userId } = props

    const { openConfirmationDialog } = useConfirmationDialogContext()

    const vm = React.useMemo(() => {
      return positionId && new GoalPositionStarVm(positionId, userId)
    }, [positionId, userId])

    const changePosition = React.useCallback(() => {
      vm && vm.onClick(source)
    }, [vm, source])

    if (!vm || vm.disabled) return null

    const onClick = () => {
      if (!vm.requiresConfirmation) {
        changePosition()
        return
      }

      openConfirmationDialog('replace-starred-position', {
        title: 'Changing your starred position',
        body: vm.confirmationContent,
        confirmLabel: vm.confirmationCta,
        onConfirm: changePosition,
      } satisfies ConfirmationDialogProps)
    }

    return (
      <>
        <GlobalConfirmationDialog id={'replace-starred-position'} />
        <Tooltip content={vm.tooltipContent}>
          <button
            className={cn(className, 'flex')}
            type="button"
            onClick={onClick}
          >
            <Star
              aria-hidden
              className={vm.className}
              size={size}
              weight={vm.weight}
            />
          </button>
        </Tooltip>
      </>
    )
  }
)
