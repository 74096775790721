import { Link } from 'src/design-system'
import React from 'react'
import { CheckinProgressBar } from 'components/checkin-progress-bar'
import { store } from 'store/index'
import { StartCheckinButton } from 'components/start-checkin-button'

export class GrowthOverTimeChartEmptyStateVm {
  constructor(private userId: string) {}

  get titleText() {
    if (!this.user) return null

    return this.lastUserCheckin
      ? this.isCurrentUser
        ? 'You have an open Check-in'
        : `${this.user.fname} has an open Check-in`
      : 'Get started now'
  }

  get descriptionText() {
    const currentUserText = 'See your strengths and where you can improve.'
    const otherUserText = 'See their strengths and where they can improve.'

    if (this.lastUserCheckin) {
      if (this.currentUserInvolvedInLatestCheckin) {
        return this.isCurrentUser
          ? `Complete your Check-in to ${currentUserText.toLowerCase()}`
          : `Complete their Check-in to ${otherUserText.toLowerCase()}`
      } else {
        return `Come back when their Check-in is complete to ${otherUserText.toLowerCase()}`
      }
    } else {
      return this.isCurrentUser ? currentUserText : otherUserText
    }
  }

  get checkinSlotContents() {
    if (!this.user) return null

    return this.lastUserCheckin ? (
      <Link href={this.lastUserCheckin.path} underline={false}>
        <CheckinProgressBar checkin={this.lastUserCheckin} />
      </Link>
    ) : (
      <StartCheckinButton
        className="mx-auto"
        source="growth-over-time-chart-empty-state"
        user={this.user}
      />
    )
  }

  private get currentUserInvolvedInLatestCheckin() {
    if (!store.currentUser) return false

    return this.lastUserCheckin.userIds.includes(store.currentUser.id)
  }

  private get lastUserCheckin() {
    return store.checkins.lastUserCheckin(this.userId)
  }

  private get isCurrentUser() {
    return this.userId === store.currentUser?.id
  }

  private get user() {
    return store.users.byId(this.userId)
  }
}
