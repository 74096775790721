import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ActionsSection } from './actions-section'
import { Header } from './header'
import { SkillsSection } from './skills-section'
import { store } from 'store/index'
import { FocusSkillsSection } from './skills-section/focus-skills-section'
import { AddFocusSkillsModal } from 'components/add-focus-skills-modal'
import { UpdatesDrawer } from 'components/updates-drawer'
import { ReflectionReminderBanner } from 'components/reflection-reminder-banner'
import { LatestActivitySection } from './skills-section/latest-activity-section'
import { PackageProtect } from 'components/package-protect'
import { GrowthOverTimeChart } from 'components/growth-over-time-chart'
import { Link } from 'src/design-system'

type GrowthProfilePageProps = {
  userId: string
}

export const GrowthProfilePage = observer((props: GrowthProfilePageProps) => {
  const { userId } = props
  const [isLoading, setIsLoading] = React.useState(true)
  const [updateFlag, setUpdateFlag] = React.useState(false)

  React.useEffect(() => {
    const handleReload = () => {
      const fetchUser = async () => {
        try {
          await store.users.fetchOne(
            userId,
            {
              include: ['manager', 'position', 'team'],
            },
            { bypassCache: true }
          )
          setUpdateFlag((prev) => !prev) // force re-render.
        } catch (error) {
          console.error('Error fetching user:', error)
        }
      }
      fetchUser()
    }

    window.addEventListener('userInfoUpdated', handleReload)

    return () => {
      window.removeEventListener('userInfoUpdated', handleReload)
    }
  }, [])

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchActions = async () => {
        await store.actions.fetchAll({
          filter: { user_id: userId },
          include: ['skills'],
          page: { size: 50 },
        })
      }

      const fetchActivities = async () => {
        await store.activities.fetchAllWithAllIncludes(50, {
          user_id: userId,
        })
      }

      const fetchFeedback = async () => {
        await store.feedbackItems.fetchAll({
          filter: { user_id: userId },
          include: ['author', 'skills'],
          page: { size: 50 },
        })
      }

      const fetchFinalisedCheckins = async () => {
        await store.checkins.fetchAll({
          filter: { author_id: userId, status: 'finalised' },
          include: ['checkin_skills', 'checkin_skills.skill'],
          page: { size: 50 },
        })
      }

      const fetchLastCheckin = async () => {
        await store.checkins.fetchAll({
          filter: { author_id: userId },
          include: ['checkin_skills', 'checkin_skills.skill'],
          page: { size: 1 },
        })
      }

      const fetchPositionChanges = async () => {
        await store.positionChanges.fetchAll({ filter: { user_id: userId } })
      }

      const user = store.users.byId(userId)

      const fetchPositionSkills = async () => {
        if (!user?.position) return

        await store.positionSkills.fetchForPosition(user.position.id)
      }

      const fetchUserSkills = async () => {
        if (store.currentUser?.id === userId) return

        await store.userSkills.fetchForUser(userId)
      }

      const fetchWins = async () => {
        await store.wins.fetchAll({
          filter: { winner_id: userId },
          include: ['skills', 'win_category', 'winners'],
          page: { size: 50 },
        })
      }

      await Promise.all([
        fetchActions(),
        fetchActivities(),
        fetchFeedback(),
        fetchFinalisedCheckins(),
        fetchLastCheckin(),
        fetchPositionChanges(),
        fetchPositionSkills(),
        fetchUserSkills(),
        fetchWins(),
      ])
    }

    fetchData().catch((error) => {
      console.error('Error fetching data:', error)
      setIsLoading(false)
    })
  }, [userId])

  const user = store.users.byId(userId)
  if (!user) return null

  const currentUser = store.nonNullCurrentUser
  const userSkills = store.userSkills.forUser(userId)

  const isSelf = user.isCurrentUser
  const isSenior = currentUser.allReports.includes(user)
  const isAdmin = currentUser.isAdmin
  const isEditorOfTeam = !!user.team?.hasEditor(currentUser.id)
  const isSelfOrSenior = isSelf || isSenior
  const isSeniorOrAdmin = isSenior || isAdmin
  const isSelfSeniorOrAdmin = isSelf || isSeniorOrAdmin
  const isMembershipVisible =
    isSelfSeniorOrAdmin || !!currentUser.org?.membershipsVisible

  return (
    <article>
      <Header
        isLoading={isLoading}
        user={user}
        showEdit={
          isAdmin ||
          isSenior ||
          isEditorOfTeam ||
          (isSelf && !!user.org?.canSelfAssignPosition)
        }
        showInbox={isSelf}
        showPosition={isMembershipVisible}
        showCareerPath={isSelfSeniorOrAdmin}
        quickActionsProps={{
          showWin: !isSelf,
          showFeedback: !isSelf,
          showAction: !isSelf && isSeniorOrAdmin,
        }}
      />
      <div className="container flex flex-col gap-14 items-center max-w-[880px] mx-auto py-10">
        <div className="flex flex-col gap-y-8 w-full">
          {isSelfSeniorOrAdmin && <FocusSkillsSection user={user} />}
          {isMembershipVisible && (
            <SkillsSection
              user={user}
              showPreview={!isSelfSeniorOrAdmin}
              showFocused={!isSelfSeniorOrAdmin}
            />
          )}
        </div>
        <PackageProtect>
          {isSelfSeniorOrAdmin && (
            <div className="flex flex-col gap-5 items-start w-full">
              <div className="flex justify-between items-center w-full">
                <h2 className="font-bold mb-0 text-gray-900 text-xl">
                  Completed Check-ins
                </h2>
                <Link
                  href={`/check-ins?user_id=${user.id}`}
                  underline={false}
                  data-turbo-action="advance"
                >
                  View all
                </Link>
              </div>
              <GrowthOverTimeChart userId={userId} timePeriod="all time" />
            </div>
          )}
          <LatestActivitySection
            user={user}
            showActionButton={isSelfSeniorOrAdmin}
          />
          {isSelfOrSenior && <ActionsSection user={user} />}
        </PackageProtect>
      </div>
      <UpdatesDrawer
        skillIds={userSkills.map((userSkill) => userSkill.skill.id)}
        userId={user.id}
      />
      <AddFocusSkillsModal />
    </article>
  )
})
