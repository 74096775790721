import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { User } from 'store/modules/users'
import { SuggestedSkillsProvider } from './suggested-skills'
import { FocusSkillTable } from './focus-skills/focus-skill-table'
import { store } from 'store/index'

type FocusSkillsSectionProps = {
  user: User
}

export const FocusSkillsSection = observer((props: FocusSkillsSectionProps) => {
  const { user } = props

  const hasFocusSkills = store.userSkills.focusedForUser(user.id).length > 0

  const hasSuggestedSkills =
    store.userSkills.suggestedForUser(user.id).length > 0

  if (!hasFocusSkills && !store.currentUser?.isAdmin && !user.isCurrentUser) {
    return null
  }

  return (
    <SuggestedSkillsProvider source="growth_profile" user={user}>
      <section className="flex flex-col gap-4 max-w-full overflow-x-auto w-full">
        <h2 className="font-bold mb-0 text-gray-900 text-xl">Starred skills</h2>
        {hasFocusSkills || (hasSuggestedSkills && user.isCurrentUser) ? (
          <FocusSkillTable showSuggestions={user.isCurrentUser} />
        ) : (
          <div className="p-8 flex items-center justify-center text-gray-600">
            {user.isCurrentUser
              ? 'You have no starred skills set.'
              : `${user.fname} has no starred skills set.`}
          </div>
        )}
      </section>
    </SuggestedSkillsProvider>
  )
})
