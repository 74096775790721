import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { observer } from 'mobx-react-lite'
import { TooltipProps } from 'recharts'
import * as React from 'react'
import { Tag } from 'src/design-system'
import { Position } from 'store/modules/positions'

export const HoverCard = observer(
  (
    props: TooltipProps<ValueType, NameType> & {
      positionOneLabel?: string | null
      positionTwoLabel?: string | null
    }
  ) => {
    const { payload, positionOneLabel, positionTwoLabel } = props

    const chartData = payload?.[0]

    if (!chartData) return null

    return (
      <div className="bg-white border border-gray-100 flex flex-col gap-y-1 min-w-[220px] p-3 rounded text-xs w-auto whitespace-nowrap">
        <p className="font-semibold mb-0 text-gray-900">
          {chartData.payload.label}
        </p>
        <p className="mb-3 text-gray-600">{chartData.payload.category}</p>
        <div className="flex flex-col gap-2 mb-3">
          {positionOneLabel && (
            <div className="flex flex-row justify-between gap-2">
              <p className="mb-0 text-gray-600">{positionOneLabel}</p>
              <Tag className="ml-auto" variant="paper">
                {chartData.payload.positionOne === 0
                  ? 'N/A'
                  : `L${chartData.payload.positionOne}`}
              </Tag>
            </div>
          )}
          {positionTwoLabel && (
            <div className="flex flex-row justify-between gap-2">
              <p className="mb-0 text-gray-600">{positionTwoLabel}</p>
              <Tag className="ml-auto" variant="paper">
                {chartData.payload.positionTwo === 0
                  ? 'N/A'
                  : `L${chartData.payload.positionTwo}`}
              </Tag>
            </div>
          )}
        </div>
      </div>
    )
  }
)
