import * as React from 'react'
import { TooltipProps } from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { format } from 'date-fns'
import { CheckinStatusLabel } from 'components/checkin-status-label'
import { GrowthOverTimeTooltipVm } from './growth-over-time-tooltip-vm'

export const GrowthOverTimeTooltip = (
  props: TooltipProps<ValueType, NameType>
) => {
  const chartData = props.payload

  // hovering on chart with not point plotted will mean there is no payload
  if (!chartData) return null

  const checkinData = chartData[0] || null
  const plottedSkills = chartData.map((datum) => datum.payload.name)

  const tooltipVm = new GrowthOverTimeTooltipVm(
    plottedSkills,
    checkinData?.payload?.checkinId
  )

  const skillsToDisplay = tooltipVm.skillsToDisplay

  if (!skillsToDisplay) return null

  return (
    <div className="rounded border min-w-[266px] z-tooltip text-gray-600 border-gray-100 p-4 bg-white flex flex-col gap-y-1 shadow-modal">
      {tooltipVm.checkin && (
        <>
          <p className="text-grey-900 font-bold mb-0">
            {tooltipVm.checkin.positionName}
          </p>
          <p className="mb-4">
            {tooltipVm.checkin.finalisedAt &&
              format(tooltipVm.checkin.finalisedAt, 'dd MMM yyyy')}
          </p>
        </>
      )}
      {skillsToDisplay.slice(0, 3).map((skill, index) => {
        return (
          <div
            key={index}
            className="flex flex-row items-center justify-between gap-x-2"
          >
            <div className="w-1/2 truncate">
              {skill.name} L{skill.level}
            </div>
            <div className="w-1/2">
              {skill.status && <CheckinStatusLabel status={skill.status} />}
            </div>
          </div>
        )
      })}
      {skillsToDisplay.length - 3 > 0 && (
        <p>+ {skillsToDisplay.length - 3} more</p>
      )}
    </div>
  )
}
