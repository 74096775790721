import * as React from 'react'
import { MenuProps, components } from 'react-select'
import { TeamOption } from '../team-select.types'

export const Menu = (props: MenuProps<TeamOption>) => (
  <components.Menu
    {...props}
    className="max-w-[240px] shadow-card border-px border-solid border-gray-100 rounded-lg -mt-2 ml-10 overflow-hidden"
  />
)
