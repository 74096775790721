import { trackEvent } from 'app/packs/src/services/event-tracker'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { User } from 'store/modules/users'
import { UserSkill } from 'store/modules/user_skills'

type SuggestedSkillsContextProps = {
  focusSkill: (userSkill: UserSkill) => void
  focusSkills: UserSkill[]
  shuffleSuggestedUserSkill: (userSkill: UserSkill) => void
  suggestedUserSkills: UserSkill[]
}

const SuggestedSkillsContext = React.createContext(
  {} as SuggestedSkillsContextProps
)

type SuggestedSkillsProviderProps = {
  children: React.ReactNode
  source: string
  user: User
}

export const SuggestedSkillsProvider = observer(
  ({ children, source, user }: SuggestedSkillsProviderProps) => {
    const [suggestedUserSkills, setSuggestedUserSkills] = React.useState(
      store.userSkills.suggestedForUser(user.id)
    )

    const all = store.userSkills.suggestedForUser(user.id, true)

    const focusSkills = store.userSkills
      .focusedForUser(user.id)
      .sort((a, b) => {
        return b.updatedAt.getTime() - a.updatedAt.getTime()
      })

    const suggestedUserSkillIds = suggestedUserSkills.map(
      (userSkill) => userSkill.id
    )

    const otherSuggestedSkills = React.useCallback(
      (skillId: string) => {
        return all.filter((suggestedUserSkill) => {
          return (
            !suggestedUserSkillIds.includes(suggestedUserSkill.id) &&
            suggestedUserSkill.skill.id !== skillId
          )
        })
      },
      [all, suggestedUserSkillIds]
    )

    const shuffleSuggestedUserSkill = React.useCallback(
      (userSkill: UserSkill) => {
        trackEvent('$track_focus_skill_shuffled', {
          skillId: userSkill.skill.id,
          skillName: userSkill.skill.name,
          source,
        })

        // Filter out the original suggested skill
        const remainingSuggestedSkills = otherSuggestedSkills(
          userSkill.skill.id
        )

        // Pick a random suggested skill
        const newSuggestedSkill =
          remainingSuggestedSkills[
            Math.floor(Math.random() * remainingSuggestedSkills.length)
          ]

        // Find index of the original suggested skill
        const index = suggestedUserSkills.indexOf(userSkill)

        if (~index) {
          // Replace original suggested skill with the new one
          const newSuggestedSkills = [...suggestedUserSkills]
          newSuggestedSkills[index] = newSuggestedSkill
          setSuggestedUserSkills(newSuggestedSkills)
        }
      },
      [otherSuggestedSkills, source, suggestedUserSkills]
    )

    const focusSkill = React.useCallback(
      async (userSkill: UserSkill) => {
        await userSkill.update({ focused: true })

        setSuggestedUserSkills(
          [...suggestedUserSkills].filter((suggestedUserSkill) => {
            return suggestedUserSkill.id !== userSkill.id
          })
        )
      },
      [suggestedUserSkills]
    )

    React.useEffect(() => {
      const removeSuggestedUserSkill = ((event: CustomEvent) => {
        setSuggestedUserSkills(
          [...suggestedUserSkills].filter((suggestedUserSkill) => {
            return suggestedUserSkill.skill.id !== event.detail.id
          })
        )
      }) as EventListener

      const addSuggestedUserSkill = ((event: CustomEvent) => {
        const newSuggestedUserSkill = otherSuggestedSkills(event.detail.id)[0]

        if (newSuggestedUserSkill) {
          setSuggestedUserSkills([
            ...suggestedUserSkills,
            newSuggestedUserSkill,
          ])
        }
      }) as EventListener

      document.addEventListener('focusskill:added', removeSuggestedUserSkill)
      document.addEventListener('focusskill:removed', addSuggestedUserSkill)

      return () => {
        document.removeEventListener(
          'focusskill:added',
          removeSuggestedUserSkill
        )

        document.removeEventListener(
          'focusskill:removed',
          addSuggestedUserSkill
        )
      }
    }, [otherSuggestedSkills, suggestedUserSkills])

    return (
      <SuggestedSkillsContext.Provider
        value={{
          focusSkill,
          focusSkills,
          shuffleSuggestedUserSkill,
          suggestedUserSkills,
        }}
      >
        {children}
      </SuggestedSkillsContext.Provider>
    )
  }
)

export const useSuggestedSkillsContext = () => {
  return React.useContext(SuggestedSkillsContext)
}
