import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { useSuggestedSkillsContext } from '../suggested-skills'
import {
  FocusSkillRow,
  SuggestedSkillRow,
  rowBorderClasses,
} from './focus-skill-row'
import { PackageProtect } from 'components/package-protect'
import { store } from 'store/index'

type FocusSkillTableProps = {
  showSuggestions?: boolean
}

export const FocusSkillTable = observer((props: FocusSkillTableProps) => {
  const currentUser = store.nonNullCurrentUser

  const { showSuggestions } = props

  const {
    focusSkills,
    suggestedUserSkills,
    shuffleSuggestedUserSkill,
    focusSkill,
  } = useSuggestedSkillsContext()

  return (
    <table>
      <thead>
        <tr className="children:px-2 children:py-6">
          <th
            className={cn(
              currentUser.hasPackage('grow')
                ? 'max-w-[250px] w-[250px]'
                : 'w-full'
            )}
          >
            Skill
          </th>
          <th>Level</th>
          <PackageProtect>
            <th>Activity</th>
            <th>Streak</th>
          </PackageProtect>
        </tr>
      </thead>
      <tbody>
        {focusSkills.map((focusSkill) => (
          <FocusSkillRow key={focusSkill.id} userSkill={focusSkill} />
        ))}
        {showSuggestions &&
          suggestedUserSkills.map((userSkill) => (
            <SuggestedSkillRow
              className={`${rowBorderClasses} children:p-2`}
              key={userSkill.id}
              onFocusClick={focusSkill}
              onShuffleClick={shuffleSuggestedUserSkill}
              userSkill={userSkill}
            />
          ))}
      </tbody>
    </table>
  )
})
