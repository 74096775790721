import * as React from 'react'
import { observer } from 'mobx-react-lite'
import pluralize from 'pluralize'
import cn from 'classnames'
import { Card } from 'src/design-system'
import { User } from 'store/modules/users'
import { openModal as openRailsModal } from '../../../utils/open-modal'
import { store } from 'store/index'
import { PositionModalButton } from 'components/position-modal/position-modal-button'

export type CurrentPositionCardProps = {
  user: User
  className?: string
}

export const CurrentPositionCard = observer(
  (props: CurrentPositionCardProps) => {
    const { user, className } = props
    const { currentUser, checkins } = store

    const position = user.position
    const canCurrentUserEdit =
      user.org?.canSelfAssignPosition &&
      (user.isCurrentUser || currentUser?.directReports.includes(user))
    const showSetPosition = canCurrentUserEdit || currentUser?.isAdmin

    const choosePosition = () => {
      openRailsModal(`/org_users/${user.id}/edit?find_by_user_id=true`)
    }

    if (!position) {
      return (
        <Card className={cn('p-4 flex flex-col gap-1', className)}>
          <span className="text-xs text-gray-900">Current position</span>
          <p className="font-bold text-gray-300 text-lg m-0 whitespace-nowrap truncate">
            No current position
          </p>
          {showSetPosition && (
            <button
              className="text-theme-40 hover:text-theme-30 w-fit"
              onClick={choosePosition}
            >
              Set position
            </button>
          )}
        </Card>
      )
    }

    const checkinCount = checkins.forAuthorAndPosition(
      user.id,
      position.id
    ).length

    const activeCheckinCount = checkins.notFinalisedForAuthorAndPosition(
      user.id,
      position.id
    ).length

    return (
      <PositionModalButton className="contents" positionId={position.id}>
        <Card
          className={cn(
            'flex flex-col items-start gap-1 m-0 p-4 border-solid border-transparent hover:border-theme-50',
            className
          )}
        >
          <span className="text-gray-900 text-xs">Current position</span>
          <p className="text-gray-900 font-bold text-lg m-0 whitespace-normal truncate text-left">
            {position.name}
          </p>
          {checkinCount > 0 && (
            <p className="m-0 whitespace-nowrap">
              {pluralize('Check-in', checkinCount, true)}
              {activeCheckinCount > 0 && ` (${activeCheckinCount} Active)`}
            </p>
          )}
        </Card>
      </PositionModalButton>
    )
  }
)
