import * as React from 'react'
import { store } from 'store/index'
import { UserSkill } from 'store/modules/user_skills'

export const useFocusSkillRow = (userSkill: UserSkill) => {
  const user = userSkill.user
  const currentUser = store.nonNullCurrentUser
  const checkin = store.checkins.lastFinalisedUserCheckin(user.id)
  const checkinSkills = checkin?.checkinSkills

  const skillRequirementLevel = React.useMemo(() => {
    return userSkill.positionRequirementLevel
  }, [userSkill])

  const checkinSkill = React.useMemo(() => {
    return checkinSkills?.find(
      (checkinSkill) => checkinSkill.skill.id === userSkill.skill.id
    )
  }, [checkinSkills, userSkill.skill.id])

  const checkinSkillLevel = checkinSkill?.finalLevel || null
  const checkinSkillDate = checkinSkill?.checkin.assessorApprovedAt

  const clickable = React.useMemo(() => {
    if (!currentUser.org?.activeSubscription) return false

    return currentUser.hasPackage('grow')
  }, [currentUser])

  const editable = React.useMemo(() => {
    return user?.isCurrentUser && !!user?.org?.activeSubscription
  }, [user])

  const onClickViewActivity = () => {
    if (!clickable) return false

    const event = new CustomEvent('updatesdrawer:open', {
      detail: {
        id: userSkill.skill.id,
        userId: user.id,
        name: userSkill.skill.name,
        description: userSkill.skill.description,
        requirementLevel: skillRequirementLevel,
        checkinLevel: checkinSkillLevel,
        checkinDate: checkinSkillDate,
        source: 'clicking view activity on growth profile focus skill card',
      },
    })

    document.dispatchEvent(event)
  }

  return {
    checkinSkillLevel,
    clickable,
    editable,
    onClickViewActivity,
    skillRequirementLevel,
    user,
  }
}
