import { store } from 'store/index'

export class GrowthOverTimeTooltipVm {
  constructor(private plottedSkills: string[], private checkinId: string) {}

  get checkin() {
    return store.checkins.byId(this.checkinId)
  }

  get skillsToDisplay() {
    return this.checkin?.checkinSkills
      .filter((checkinSkill) =>
        this.plottedSkills.includes(checkinSkill.skill.name)
      )
      .map((checkinSkill) => {
        return {
          name: checkinSkill.skill.name,
          level: checkinSkill.finalLevel,
          status: checkinSkill.status,
        }
      })
  }
}
