import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { ArrowRight, Star } from '@phosphor-icons/react'
import cn from 'classnames'
import { Card, Link, ModalButton } from 'src/design-system'
import { User } from 'store/modules/users'
import { store } from 'store/index'
import { successToast } from 'app/packs/src/utils/success-toast'
import { PositionSelectDialog } from './position-select-dialog'
import { PositionProgressBars } from './position-progress-bars'
import { Position } from 'store/modules/positions'
import { GoalPositionStar } from 'components/goal-position-star'
import { SOURCE } from '../utils'
import { POSITION_MODAL_ID } from 'components/position-modal/utils'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { LoadingContent } from 'components/loading-content'

export type GoalPositionCardProps = {
  user: User
  className?: string
}

export const GoalPositionCard = observer((props: GoalPositionCardProps) => {
  const { user, className } = props

  const goalPosition = user.goalPosition
  const suggestedGoalPosition = user.suggestedGoalPosition

  /**
   * This next state declaration facilitates a hack;
   *
   * When the goal position is changed, we need to refresh the new
   * position's requirements to render the {@link PositionProgressBars}
   * component. However, the store call doesn't seem to trigger a rerender
   * when it's done.
   *
   * We briefly tried debugging it but concluded that it wasn't a priority.
   */
  const [goalPositionId, setGoalPositionId] = React.useState<string>()
  const activeGoalPosition = store.positions.byId(goalPositionId)
  const fetchRequirements = async (position: Position) => {
    await store.positions.fetchOne(position.id, {
      include: [
        'requirements',
        'skills.skill_variants.skill_levels',
        'framework.frameworks_skills.skill_variant',
      ],
    })
    setGoalPositionId(position.id)
  }
  /* End hack */

  const useSuggestion = async () => {
    if (suggestedGoalPosition) {
      await store.users.update(user.id, {
        goalPosition: suggestedGoalPosition.id,
      })
      trackEvent('growth_profile_goal_selected', {
        goal: 'move-into-new-position',
        type: 'suggested-position',
      })
      successToast('Starred position updated!')
    }
  }

  React.useEffect(() => {
    if (!goalPosition) {
      setGoalPositionId(undefined)
      return
    }
    fetchRequirements(goalPosition)
  }, [goalPosition])

  return (
    <Card className={cn('m-0 p-0 border-none group', className)}>
      <LoadingContent loading={goalPosition?.id !== activeGoalPosition?.id}>
        {activeGoalPosition ? (
          <ModalButton
            modalId={POSITION_MODAL_ID}
            modalProps={{ positionId: activeGoalPosition.id }}
            className="contents"
          >
            <div className="h-full p-4 rounded flex flex-col gap-1 transition border !border-transparent group-hover:!border-theme-50">
              <header className="flex items-center justify-between">
                <span className="text-xs text-gray-900">Starred position</span>
                {user.isCurrentUser && (
                  <Link
                    href="/compare"
                    underline={false}
                    className="p-0 ml-auto group/compare hover:text-theme-30"
                    onClick={(e) => e.stopPropagation()}
                    data-turbo-frame="content"
                    data-turbo-action="advance"
                  >
                    Compare{' '}
                    <ArrowRight className="transition-transform group-hover/compare:translate-x-1" />
                  </Link>
                )}
              </header>
              <div className="flex items-center gap-1.5">
                <p className="font-bold text-gray-900 text-lg m-0 whitespace-nowrap truncate">
                  {activeGoalPosition.name}
                </p>
                {user.isCurrentUser && (
                  <div
                    // Hack to allow interacting with the goal star and its modal without
                    // triggering a card click.
                    onClick={(e) => e.stopPropagation()}
                  >
                    <GoalPositionStar
                      source={SOURCE}
                      positionId={activeGoalPosition.id}
                      userId={user.id}
                    />
                  </div>
                )}
              </div>
              <PositionProgressBars
                user={user}
                requirements={activeGoalPosition.requirements}
                className="mt-2"
              />
            </div>
          </ModalButton>
        ) : suggestedGoalPosition && user.isCurrentUser ? (
          <ModalButton
            modalId={POSITION_MODAL_ID}
            modalProps={{ positionId: suggestedGoalPosition.id }}
            className="contents"
          >
            <div className="p-4 rounded flex-grow flex flex-col gap-1 transition border !border-transparent group-hover:!border-theme-50 items-start">
              <span className="text-start text-xs text-gray-700">
                Suggested starred position
              </span>
              <p className="font-bold text-gray-300 text-lg m-0 whitespace-nowrap truncate text-left">
                {suggestedGoalPosition.name}
              </p>
              {user.isCurrentUser && (
                <div
                  className="flex items-center gap-2 text-theme-40"
                  onClick={(e) => e.stopPropagation()}
                >
                  <button
                    onClick={useSuggestion}
                    className="items-center flex hover:text-theme-30"
                  >
                    <Star weight="bold" /> Star this position
                  </button>
                  •
                  <PositionSelectDialog
                    userId={user.id}
                    initialPositionId={suggestedGoalPosition.id}
                  >
                    <button className="items-center flex hover:text-theme-30">
                      I&apos;ll choose
                    </button>
                  </PositionSelectDialog>
                </div>
              )}
            </div>
          </ModalButton>
        ) : (
          <div className="p-4 rounded-lg flex-grow items-start flex flex-col gap-1 transition border !border-transparent group-hover:!border-theme-50">
            <span className="text-xs text-gray-500">Starred position</span>
            <p className="font-bold text-gray-300 text-lg m-0 whitespace-nowrap truncate">
              No starred position
            </p>
            {user.isCurrentUser && (
              <PositionSelectDialog userId={user.id}>
                <button className="text-theme-40 width-fit hover:text-theme-30">
                  Choose a position
                </button>
              </PositionSelectDialog>
            )}
          </div>
        )}
      </LoadingContent>
    </Card>
  )
})
