import * as React from 'react'
import { LineProps } from 'recharts'
import { colors } from './growth-over-time-chart-vm'
import cn from 'classnames'

export const GrowthOverTimeDot: LineProps['dot'] = (props) => {
  const { cx, cy, payload } = props

  const isFirstPoint = payload.category === 0 && payload.value === 0

  const color = isFirstPoint ? colors['created'] : colors[payload.status]

  return (
    <svg
      x={cx - 6}
      y={cy - 6}
      width={12}
      height={12}
      className={cn(color, 'fill-current')}
    >
      <circle cx="6" cy="6" r="6" />
    </svg>
  )
}
