import * as React from 'react'
import { MultiValueGenericProps, components } from 'react-select'
import { TeamOption } from '../team-select.types'

export const MultiValueLabel = (props: MultiValueGenericProps<TeamOption>) => {
  const { children } = props

  return (
    <components.MultiValueLabel
      {...props}
      innerProps={{ className: 'flex flex-row items-center gap-x-0.5' }}
    >
      {children}
      {props.selectProps.isDisabled ? (
        <span className="mr-1"> </span>
      ) : (
        <span className="text-gray-600 mr-1">{' ,'}</span>
      )}
    </components.MultiValueLabel>
  )
}
