import * as React from 'react'
import { NoticeProps, components } from 'react-select'
import { TeamOption } from '../team-select.types'

export const NoOptionsMessage = (props: NoticeProps<TeamOption>) => {
  const hasUnselectedOptions = props.options.length === props.getValue().length

  const content = hasUnselectedOptions
    ? "There's no one else to add"
    : 'Name not recognised'

  return (
    <components.NoOptionsMessage {...props} className="p-4 text-gray-600">
      {content}
    </components.NoOptionsMessage>
  )
}
