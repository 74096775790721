import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { curveBumpX } from 'd3-shape'
import * as React from 'react'
import { GrowthOverTimeChartEmptyState } from './growth-over-time-chart-empty-state'
import { GrowthOverTimeDot } from './growth-over-time-dot'
import { GrowthOverTimeReferenceLineLabel } from './growth-over-time-reference-line-label'
import { GrowthOverTimeTooltip } from './growth-over-time-tooltip'
import { GrowthOverTimeVm } from './growth-over-time-chart-vm'
import { GrowthOverTimeYAxisTick } from './growth-over-time-y-axis-tick'
import { observer } from 'mobx-react-lite'

export type GrowthOverTimeChartProps = {
  userId: string
  timePeriod: TimePeriod
}

export const timePeriods = [
  'last checkin',
  '30 days',
  '3 months',
  '6 months',
  '12 months',
  'all time',
] as const

export type TimePeriod = (typeof timePeriods)[number]

export const GrowthOverTimeChart: React.VFC<GrowthOverTimeChartProps> =
  observer(({ userId, timePeriod }) => {
    const vm = new GrowthOverTimeVm(timePeriod, userId)

    return (
      <div className="w-full flex items-center justify-center h-[350px] max-h-[350px] max-w-[850px]">
        <div className="relative w-full h-full">
          {vm.showEmptyState && (
            <GrowthOverTimeChartEmptyState userId={userId} />
          )}
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid stroke={'#E5E5E5'} />
              <XAxis
                dataKey="category"
                type="number"
                allowDuplicatedCategory={false}
                axisLine={true}
                tickLine={false}
                stroke={'#E5E5E5'}
                tick={false}
                allowDataOverflow={true}
                domain={[vm.domainMinimum, vm.domainMaximum]}
                interval={'preserveStart'}
                padding={{ left: vm.leftPad, right: vm.rightPad }}
              />
              <YAxis
                dataKey="value"
                axisLine={true}
                tickLine={false}
                tickCount={vm.yAxisMax}
                width={5}
                allowDecimals={false}
                stroke={'#E5E5E5'}
                tick={GrowthOverTimeYAxisTick}
                fontSize="12px"
              />
              <Tooltip content={GrowthOverTimeTooltip} />
              {vm.referenceLines.map((referenceLine) => {
                return (
                  <ReferenceLine
                    key={referenceLine.x}
                    label={(args) => {
                      return (
                        <GrowthOverTimeReferenceLineLabel
                          labels={referenceLine.labels}
                          x={args.viewBox.x + 13.25}
                        />
                      )
                    }}
                    stroke={referenceLine.stroke}
                    x={referenceLine.x}
                  />
                )
              })}
              {vm.chartData.map((series) => {
                return (
                  <Line
                    dataKey={'value'}
                    data={series.data}
                    key={series.name}
                    name={series.name}
                    type={curveBumpX}
                    className={series.color}
                    stroke="currentColor"
                    strokeDasharray={series.dashed ? '4' : undefined}
                    strokeWidth={2}
                    dot={GrowthOverTimeDot}
                    // FIXME: throws a TS error ?
                    // activeDot={GrowthOverTimeDot}
                    connectNulls={true}
                    isAnimationActive={false}
                  />
                )
              })}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  })
