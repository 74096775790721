import * as React from 'react'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import { CaretDown, CaretUp } from '@phosphor-icons/react'
import { SkillListItem } from './skill-list-item'
import { SkillsSectionEmptyState } from './skills-section-empty-state'
import { store } from 'store/index'
import { User } from 'store/modules/users'
import { Button } from 'src/design-system'
import { PackageProtect } from 'components/package-protect'

type SkillsSectionProps = {
  user: User
  showPreview?: boolean
  showFocused?: boolean
}

export const SkillsSection = observer((props: SkillsSectionProps) => {
  const { user, showPreview, showFocused } = props

  const [showAll, setShowAll] = React.useState(false)
  const skills = showFocused
    ? store.userSkills.forUser(user.id)
    : store.userSkills.unfocusedForUser(user.id)
  const shownSkills = !showAll && showPreview ? skills.slice(0, 3) : skills

  const showDetails =
    user.isCurrentUser ||
    store.currentUser?.isAdmin ||
    user.manager?.id === store.currentUser?.id

  return (
    <section className="flex flex-col gap-4 max-w-full overflow-x-auto w-full">
      <h2
        className={cn('font-bold text-gray-900 text-xl', {
          'sr-only': !showAll && !showPreview,
          'mb-4': showDetails,
        })}
      >
        All skills
      </h2>
      {(showAll || showPreview) && (
        <table className="min-w-full table-auto">
          {showDetails && (
            <thead>
              <tr className="font-bold text-sm">
                <th className="md:pr-8 pr-4">Name</th>
                <th className="md:pr-8 pr-4">Level</th>
                <PackageProtect>
                  <th className="md:pr-8 pr-4">Activity</th>
                </PackageProtect>
              </tr>
            </thead>
          )}
          <tbody>
            {shownSkills.length > 0 ? (
              shownSkills.map((userSkill) => (
                <SkillListItem
                  key={userSkill.id}
                  user={user}
                  userSkill={userSkill}
                  showDetails={showDetails}
                />
              ))
            ) : (
              <SkillsSectionEmptyState user={user} />
            )}
          </tbody>
        </table>
      )}
      <ShowHideButton
        className="mx-auto my-4"
        showAll={showAll}
        setShowAll={setShowAll}
      />
    </section>
  )
})

const ShowHideButton: React.VFC<{
  showAll: boolean
  setShowAll: (value: boolean) => void
  className?: string
}> = (props) => {
  const { showAll, setShowAll, className } = props

  const Icon = showAll ? CaretUp : CaretDown

  return (
    <Button
      variant="outline"
      className={cn('py-2 mb-2', className)}
      onClick={() => setShowAll(!showAll)}
    >
      {showAll ? 'Hide all skills' : 'Show all skills'}
      <Icon className="h-3.5 text-theme-40 w-3.5" weight="bold" />
    </Button>
  )
}
