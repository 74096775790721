import {
  Button,
  GlobalModal,
  useModalContext,
  useModalProps,
} from 'src/design-system'
import { X } from '@phosphor-icons/react'
import * as React from 'react'
import { ADD_FOCUS_SKILLS_MODAL_ID } from './utils'
import { User } from 'store/modules/users'
import { SkillSelect } from 'components/skill-select'
import { store } from 'store/index'
import { observer } from 'mobx-react-lite'

export const AddFocusSkillsModal = observer(() => {
  const { user } = useModalProps<{
    user?: User
  }>(ADD_FOCUS_SKILLS_MODAL_ID)

  const { closeModal } = useModalContext()

  const [error, setError] = React.useState('')

  const onSubmit = async () => {
    if (!user) return

    const userSkills = store.userSkills
      .unfocusedForUser(user.id)
      .filter((userSkill) => {
        return skillIds.includes(userSkill.skill.id)
      })

    let error = ''

    try {
      await Promise.all(
        userSkills.map(async (userSkill) => {
          const response = await userSkill.update({ focused: true })
          if (!response.success) throw response.errors?.[0]?.title
        })
      )
    } catch (e) {
      error = typeof e === 'string' ? e : ''
    } finally {
      if (!error) {
        closeModal()
        error = ''
      }
      setError(error)
    }
  }

  const [skillIds, setSkillIds] = React.useState<string[]>([])

  const skills = React.useMemo(() => {
    if (!user) return []

    const focusedForUser = store.userSkills
      .focusedForUser(user.id)
      .map((m) => m.id)

    return store.userSkills
      .forUser(user.id)
      .filter((userSkill) => !focusedForUser.includes(userSkill.id))
      .map((s) => s.skill)
  }, [user])

  let title = 'Add focus skills'
  if (!user?.isCurrentUser) title += ' for ' + user?.fullName

  return (
    <GlobalModal.Root
      id={ADD_FOCUS_SKILLS_MODAL_ID}
      title={title}
      className="!max-w-[500px] w-full h-auto"
    >
      <div className="py-1 pl-4 pr-2 border-0 border-b border-gray-100 border-solid flex flex-row items-center justify-between">
        <h4 className="font-bold text-sm text-gray-900">{title}</h4>
        <GlobalModal.CloseButton className="p-2 ml-auto flex items-center rounded-sm !outline-none border-px border-solid border-transparent focus:!border-theme-60">
          <X weight="bold" className="text-gray-900 w-4 h-4" />
          <span className="sr-only">Close</span>
        </GlobalModal.CloseButton>
      </div>
      <div className="p-6">
        <p>
          You can only set 3 focus skills, but we&apos;ll be looking to expand
          this limit in the near future!
        </p>
        {error && <p className="text-red-600 mb-2">{error}</p>}

        <SkillSelect
          skills={skills}
          focusSkillIds={[]}
          name="skill_ids"
          required
          source="add-focus-skills-modal"
          onChange={(e) => setSkillIds(e)}
        />
        <div className="flex justify-end gap-2 mt-4">
          <Button onClick={closeModal} type="button" variant="outline">
            Cancel
          </Button>
          <Button onClick={onSubmit} type="button">
            Add focus skills
          </Button>
        </div>
      </div>
    </GlobalModal.Root>
  )
})
