import { SkillsRadarChartDataPoint } from './../../components/skills-radar-chart/skills-radar-chart.types'
import * as React from 'react'
import { SkillCard } from './skill-card'
import { Tag } from 'src/design-system'

export type SkillDiffCategoryProps = {
  category: string
  positionOneSkills: SkillsRadarChartDataPoint[]
  positionTwoSkills: SkillsRadarChartDataPoint[]
  openedSkillId?: string | null
  setOpenedSkillId: (skillId: string | null) => void
}

export const SkillDiffCategory = (props: SkillDiffCategoryProps) => {
  const {
    category,
    positionOneSkills,
    positionTwoSkills,
    setOpenedSkillId,
    openedSkillId,
  } = props

  const p1Ids = positionOneSkills.map((p1) => p1.id)
  const p2Ids = positionTwoSkills.map((p2) => p2.id)

  const allSkillIds = p1Ids.concat(
    p2Ids.filter((item) => p1Ids.indexOf(item) < 0)
  )

  const skillGridAreas = [
    '"position-one position-two"',
    ...allSkillIds.map((skill) => `"skill-${skill} skill-${skill}"`),
  ].join('\n')

  return (
    <div className="mt-12">
      <div className="flex gap-x-2 mx-8 px-8 items-center text-gray-600 text-base font-bold">
        {category} <Tag variant="paper">{allSkillIds.length}</Tag>
      </div>
      <div
        key={Math.random()}
        className="grid grid-cols-2 gap-x-16 gap-y-12 mx-8 px-8"
        style={{ gridTemplateAreas: skillGridAreas }}
      >
        {allSkillIds.map((skillId) => {
          return (
            <React.Fragment key={skillId}>
              <div
                key={Math.random()}
                style={{
                  gridColumn: 'position-one',
                  gridRow: `skill-${skillId}`,
                }}
              >
                {p1Ids.indexOf(skillId) > -1 ? (
                  <SkillCard
                    key={skillId}
                    skill={positionOneSkills.find(
                      (skill) => skill.id === skillId
                    )}
                    onOpenChange={(open: boolean) => {
                      setOpenedSkillId(open ? skillId : null)
                    }}
                    open={openedSkillId === skillId}
                    style={{
                      gridColumn: 'position-one',
                      gridRow: `skill-${skillId}`,
                    }}
                  />
                ) : (
                  <NotInPosition />
                )}
              </div>

              <div
                key={Math.random()}
                style={{
                  gridColumn: 'position-two',
                  gridRow: `skill-${skillId}`,
                }}
              >
                {p2Ids.indexOf(skillId) > -1 ? (
                  <SkillCard
                    key={skillId}
                    skill={positionTwoSkills.find(
                      (skill) => skill.id === skillId
                    )}
                    onOpenChange={(open: boolean) => {
                      setOpenedSkillId(open ? skillId : null)
                    }}
                    open={openedSkillId === skillId}
                    style={{
                      gridColumn: 'position-two',
                      gridRow: `skill-${skillId}`,
                    }}
                  />
                ) : (
                  <NotInPosition />
                )}
              </div>
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

const NotInPosition = () => (
  <div className="flex w-full h-full items-center justify-center text-gray-900 text-sm">
    Not in position
  </div>
)
