import React from 'react'
import { Link, LinkProps } from 'src/design-system'

export type InboxLinkCardProps = {
  href: LinkProps['href']
  children: React.ReactNode
}

export const InboxLinkCard: React.VFC<InboxLinkCardProps> = (props) => {
  const { href, children } = props
  return (
    <Link
      href={href}
      underline={false}
      className="block p-0"
      data-turbo-frame="content"
      data-turbo-action="advance"
    >
      <article className="p-4 rounded bg-white/90 text-gray-900 flex items-center gap-2 transition border !border-transparent hover:!border-theme-50">
        {children}
      </article>
    </Link>
  )
}
