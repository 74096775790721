import { PositionSelect } from 'components/position-select'
import { TeamSelect } from 'components/team-select'
import { UserSelect } from 'components/user-select'
import * as React from 'react'
import { store } from 'store/index'
import { UserSettingsVm } from './user-settings-vm'
import { Loader } from 'src/design-system'

export type UserSettingsProps = {
  userId: string
  allowChangePosition: boolean
}

export const UserSettings = (props: UserSettingsProps) => {
  const { userId, allowChangePosition } = props

  const vm = new UserSettingsVm(userId)

  const [teamId, setTeamId] = React.useState(vm.initialTeamId)
  const [positionId, setPositionId] = React.useState(vm.initialPositionId)
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    if (store.positions.byId(positionId)?.team.id != teamId) {
      setPositionId(undefined)
    }

    const fetchUser = async () => {
      await store.users.fetchOne(userId, {
        include: ['manager', 'position', 'team'],
      })
      setLoaded(true)
    }

    fetchUser()
  }, [teamId, positionId, userId])

  if (!allowChangePosition) return null

  if (!loaded) return <Loader />

  return (
    <>
      <div className="form-group string required">
        <label className="form-control-label string optional">
          Select team
        </label>
        <TeamSelect
          className="w-full"
          borderless={false}
          prefix=""
          aria-label="Team"
          name="org_user[team_id]"
          value={teamId}
          onChange={(newValue: string | null) =>
            setTeamId(newValue || undefined)
          }
          placeholder={'Select a team'}
          teams={store.teams.all}
          isClearable={false}
          isMulti={false}
          required={false}
        />
      </div>
      <div className="form-group string required">
        <label className="form-control-label string optional">
          Select position
        </label>
        <PositionSelect
          className="w-full"
          borderless={false}
          prefix=""
          aria-label="Position"
          name="org_user[position_id]"
          onChange={(newValue) => setPositionId(newValue || undefined)}
          value={positionId}
          placeholder={'Select a position'}
          positions={vm.positionSelectPositions(teamId)}
          isClearable={true}
          required={false}
          disablePositionsWithNoRequirements={false}
        />
      </div>
      <div className="form-group string required">
        <label className="form-control-label string optional">Manager</label>
        <UserSelect
          value={vm.user?.manager?.id}
          placeholder="Select a manager"
          users={vm.managerSelectUsers}
          isMulti={false}
          isClearable={true}
          name="org_user[manager_id]"
          className="w-full"
          borderless={false}
          required={false}
        />
      </div>
    </>
  )
}
