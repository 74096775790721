import { forRails } from 'components/for-rails'
import { GrowthProfilePage } from 'pages/growth-profile-page'
import { registerComponents } from '../src/utils/react/registry'
import { UserSettings } from 'components/user-settings'
import { ComparePage } from 'pages/compare-page'

registerComponents({
  ComparePage: forRails(ComparePage),
  GrowthProfilePage: forRails(GrowthProfilePage),
  UserSettings: forRails(UserSettings),
})
