import * as React from 'react'

export const GrowthOverTimeReferenceLineLabel = (props: {
  labels: string[]
  x: number
}) => {
  const { labels, x } = props

  return (
    <text
      className="recharts-text recharts-label text-gray-600 text-xs"
      x="0"
      y="45"
    >
      {labels.map((label, index) => {
        return (
          <tspan key={index} x={x} y={45 + index * 20}>
            {label}
          </tspan>
        )
      })}
    </text>
  )
}
