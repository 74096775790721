import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { PositionSelect } from 'components/position-select'
import { store } from 'store/index'
import { Button, Modal } from 'src/design-system'
import { successToast } from 'app/packs/src/utils/success-toast'
import { Info, Star } from '@phosphor-icons/react'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { errorToast } from 'app/packs/src/utils/error-toast'

export type PositionSelectDialogProps = {
  children: React.ReactElement
  userId: string
  initialPositionId?: string
}
export const PositionSelectDialog = observer(
  (props: PositionSelectDialogProps) => {
    const { children, initialPositionId, userId } = props

    const [open, setOpen] = React.useState(false)
    const [positionId, setPositionId] = React.useState(initialPositionId)
    const { positions } = store

    const positionSelectPositions = React.useMemo(() => {
      return positions.forOrg
    }, [positions.forOrg])

    const [submitting, setSubmitting] = React.useState(false)

    const user = store.currentUser

    const onSubmit = async () => {
      setSubmitting(true)

      const result = await store.users.update(userId, {
        goalPosition: positionId,
      })

      trackEvent('growth_profile_goal_selected', {
        goal: 'move-into-new-position',
        type: 'select-position',
      })

      if (result.success) {
        successToast('Goal position updated')
      } else {
        errorToast()
      }

      setSubmitting(false)
      setOpen(false)
    }

    if (!user) return null

    return (
      <Modal.Root
        className="w-[520px] p-8 overflow-visible"
        data-testid="position-select-dialog"
        onOpenChange={setOpen}
        open={open}
        title="Select position"
        trigger={children}
      >
        <div className="flex flex-col gap-5">
          <hgroup className="flex flex-col gap-2">
            <h2 className="text-lg font-bold">Select a starred position</h2>
            <p className="text-gray-600">
              Starring a position helps you track the skills and actions that
              will get you on the way there.
            </p>
          </hgroup>
          <div className="py-3 px-4 flex items-center gap-2 bg-theme-90">
            <Info
              className="text-theme-40 flex-shrink-0"
              size={16}
              weight="bold"
            />
            <p className="m-0 text-gray-700">
              Tip: Use <a href="/compare">Compare</a> to discover positions in
              your organisation and see how your skills match up.
            </p>
          </div>
          <div className="flex flex-col md:flex-row gap-2 w-full">
            <PositionSelect
              className="w-full"
              borderless={false}
              prefix=""
              aria-label="Position"
              onChange={(newValue) => setPositionId(newValue || undefined)}
              value={positionId}
              placeholder={'Select a position'}
              positions={positionSelectPositions}
              isClearable={true}
              required={false}
            />
            <Button
              onClick={onSubmit}
              type="button"
              disabled={submitting}
              className="px-3 flex items-center gap-1.5 self-end md:self-auto"
            >
              <Star weight="bold" />
              Save
            </Button>
          </div>
        </div>
      </Modal.Root>
    )
  }
)
