type EmptyDomainBounds = {
  [key: string]: Record<string, number>
}

export type CheckinPointOverTime = {
  category: number
  name?: string
  checkinId?: string | null
  status?: string | null
  value?: number | null
}

export type GrowthOverTimeGraphData = {
  name: string
  skillName: string | null
  color: string
  dashed: boolean
  data: CheckinPointOverTime[]
}

export const emptyStateDomainBounds: EmptyDomainBounds = {
  '12 months': { domainMinimum: 1, domainMaximum: 12 },
  '6 months': { domainMinimum: 7, domainMaximum: 12 },
  '3 months': { domainMinimum: 10, domainMaximum: 12 },
  '30 days': { domainMinimum: 10, domainMaximum: 12 },
  'all time': { domainMinimum: 0, domainMaximum: 12 },
}

export const growthChartEmptyData: GrowthOverTimeGraphData[] = [
  {
    color: 'text-gray-600',
    dashed: false,
    data: [
      {
        category: 0,
        value: 0,
        status: null,
      },
      {
        category: 2,
        value: 2,
        status: 'created',
      },
    ],
    name: 'dummySkill#0',
    skillName: null,
  },
  {
    color: 'text-gray-600',
    dashed: true,
    data: [
      {
        category: 2,
        value: 2,
        status: 'created',
        checkinId: undefined,
      },
      {
        category: 5,
        value: 3,
        status: 'created',
      },
    ],
    name: 'dummySkill#1',
    skillName: null,
  },
  {
    color: 'text-gray-600',
    dashed: true,
    data: [
      {
        category: 5,
        value: 3,
        status: 'created',
        checkinId: undefined,
      },
      {
        category: 9,
        value: 4,
        status: 'created',
      },
    ],

    name: 'dummySkill#2',
    skillName: null,
  },
  {
    color: 'text-gray-600',
    dashed: true,
    data: [
      {
        category: 9,
        value: 4,
        status: 'created',
        checkinId: undefined,
      },
      {
        category: 12,
        value: 5,
        status: 'created',
      },
    ],

    name: 'dummySkill#3',
    skillName: null,
  },
]
