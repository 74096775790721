import * as React from 'react'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { SkillLevelOutcomesSection } from 'components/skill-level-outcomes-section'
import { SkillModalButton } from 'components/skill-modal/skill-modal-button'
import { SkillsRadarChartDataPoint } from './../../components/skills-radar-chart/skills-radar-chart.types'
import { store } from 'store/index'
import { FocusSkillStar } from 'components/focus-skill-star'
import { Tag } from 'src/design-system'

type SkillCardProps = {
  skill?: SkillsRadarChartDataPoint
  className?: string
  style?: React.CSSProperties
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export const SkillCard = observer((props: SkillCardProps) => {
  const { skill, className, style, onOpenChange, open } = props

  if (!skill) return null

  const skillVariantId =
    skill.skillVariantId || store.skills.byId(skill.id)?.defaultVariant?.id

  const skillVariant = store.skillVariants.byId(skillVariantId)

  if (!skillVariant) return null

  const skillLevel = store.skillLevels.forLevelAndSkillVariant(
    skill.level,
    skillVariant.id
  )

  if (!skillLevel) return null

  const skillLevelOutcomes = skillLevel?.outcomes ?? []

  const userSkill = store.userSkills.forSkillAndUser(
    skill.id,
    store.nonNullCurrentUser.id
  )

  const maxSkillLevel =
    skillVariant.skillLevels?.reduce(
      (max, skillLevel) => Math.max(max, skillLevel?.level ?? 0),
      0
    ) || 0

  return (
    <article className={cn('flex flex-col gap-2', className)} style={style}>
      <header className="flex items-center gap-2">
        <SkillModalButton
          className="font-bold text-lg p-0 text-gray-900 cursor-pointer"
          skillId={skill.id}
          skillVariantId={skillVariantId}
          source="checkin_summary_skill_card"
        >
          {skillVariant?.fullName || skill.name}
        </SkillModalButton>
        <FocusSkillStar
          editable={true}
          size={16}
          source={'compare-page'}
          userSkill={userSkill}
        />
        {skillLevel && (
          <div className="flex flex-row items-center gap-2 ml-auto">
            <SkillTag difference={skill.difference} />
            <span className="text-gray-600">L{skillLevel.level}</span>
            <div className="flex gap-1">
              {Array.from({ length: maxSkillLevel }, (_, index) => {
                let isFilled = false
                const blobLevel = index + 1
                if (skill.level >= blobLevel) isFilled = true
                return (
                  <span
                    key={index}
                    className={cn('h-3 w-3 rounded-full mr-0 pr-0', {
                      'bg-theme-40': isFilled,
                      'bg-gray-200': !isFilled,
                    })}
                  />
                )
              })}
            </div>
          </div>
        )}
      </header>
      {skillLevel && (
        <HtmlContent className="w-full">{skillLevel.name}</HtmlContent>
      )}
      {skillLevelOutcomes.length > 0 && (
        <SkillLevelOutcomesSection
          outcomes={skillLevelOutcomes}
          onOpenChange={onOpenChange}
          open={open}
        />
      )}
    </article>
  )
})

const SkillTag = observer(
  (props: { difference: number | null | undefined }) => {
    const { difference } = props
    if (!difference) return null

    if (difference > 0) return <Tag variant="warning">+{difference}</Tag>

    return <Tag variant="warning">{difference}</Tag>
  }
)
