import { store } from 'store/index'
import { User } from 'store/modules/users'
import { can } from 'app/packs/src/policies'

export class SkillsSectionEmptyStateVm {
  constructor(private user: User) {}

  get canEditFramework() {
    if (!store.currentUser || !this.team) return false

    return can(store.currentUser, this.team).edit
  }

  get frameworkPath() {
    return this.team?.frameworkPath || ''
  }

  get text() {
    return `There are no required skills for ${
      this.user.isCurrentUser ? 'your' : `${this.user.fname}'s`
    } position.`
  }

  private get team() {
    return this.user.team
  }
}
