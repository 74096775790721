import { useModalContext } from 'src/design-system'
import { User } from 'store/modules/users'

export const ADD_FOCUS_SKILLS_MODAL_ID = 'ADD_FOCUS_SKILLS_MODAL_ID'

export const useAddFocusSkillsModal = () => {
  const { openModal } = useModalContext()

  return {
    openAddFocusSkillsModal: (user: User) =>
      openModal(ADD_FOCUS_SKILLS_MODAL_ID, { user }),
  }
}
